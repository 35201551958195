import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

import { Favorite } from '../Favorite/favorite';

interface ProductStyledProps {
  $isSmall: boolean;
  $inFavorites: boolean;
  $noHover: boolean;
}

export const ProductStyled = styled(Link)<ProductStyledProps>`
  position: relative;
  z-index: 0;
  text-decoration: none;
  color: inherit;
  display: block;
  margin-bottom: ${({ $isSmall, $inFavorites }) =>
    $isSmall ? spacing.regular : $inFavorites ? spacing.medium : spacing.block};

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    &:not(.RelatedProduct) {
      margin-bottom: ${({ $isSmall, $inFavorites }) =>
        $isSmall && $inFavorites
          ? spacing.medium
          : $isSmall
          ? spacing.regular
          : spacing.medium};
    }
  }

  .productWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ProductImage {
    aspect-ratio: 1 / 1;
    height: 100%;
    position: relative;
    background-color: ${colors.gray5};
    .safetyClass {
      position: absolute;
      display: flex;
      bottom: ${spacing.xsmall};
      right: ${spacing.xsmall};
    }
  }

  .ProductInfo {
    padding: ${spacing.xsmall} 0 0 ${spacing.xsmall};
    position: relative;

    .ProductName {
      display: block;
      margin-bottom: ${spacing.xsmall};
      color: ${colors.solidBlack};
      transition: 0.2s ease;
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.body};
      font-weight: ${fontWeights.fontMedium};

      a {
        color: ${colors.solidBlack};
      }
    }
  }

  .ProductPrice {
    margin: 0;
    font-size: ${({ $inFavorites }) =>
      $inFavorites ? fontSizes.xsmall : fontSizes.small};
    letter-spacing: 0.03125rem;
    transition: 0.2s ease;
    display: flex;
    align-items: baseline;

    .rrp {
      color: ${colors.gray1};
      font-size: ${fontSizes.bodyXS};
      margin-left: 8px;
    }
    p {
      margin: 0;
    }
  }

  .USP {
    ul {
      padding: 0;
      margin: 0;
      margin-top: 12px;
      list-style: initial;
      list-style-position: inside;
      li {
        font-size: ${fontSizes.bodyS};
        line-height: ${lineHeights.bodyS};
        color: ${colors.solidBlack};
      }
    }
  }

  .Badge {
    position: absolute;
    left: 0px;
  }

  &.TopLeft {
    top: ${spacing.xsmall};
  }

  &.BottomLeft {
    bottom: ${spacing.xsmall};
  }
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    ${({ $noHover }) =>
      !$noHover
      && css`
        &:hover {
          .ImageWrapper img {
            transform: scale(1.05);
          }
        }
      `}
  }
`;

export const StyledFavorite = styled(Favorite)`
  position: absolute;
  top: ${spacing.xsmall};
  right: ${spacing.xsmall};
  display: flex;
  z-index: 1;
  svg {
    position: initial;
    width: ${spacing.small};
    height: ${spacing.small};
  }

  &.InFavorites {
    top: -${spacing.xsmall};
    right: -${spacing.xsmall};
  }
`;

export const Outer = styled.div`
  position: relative;
`;
