import styled from 'styled-components';

export const ProductImageStyled = styled.div`
  width: 100%;
  overflow: hidden;

  a {
    box-sizing: border-box;
    inline-size: 100%;
    text-align: center;
  }
`;

export const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1;
`;
