import { useMemo, useState } from 'react';

import { ProductDetailsApiDocument } from '@hultafors/shared/types';

import { DownloadFileBox } from '../DownloadFileBox/DownloadFileBox';
import { Paragraph } from '../paragraph/paragraph';

import {
  Label,
  ProductInformationTabsStyled,
  Tab,
  Tabs,
} from './product-information-tabs.styled';

export { Tabs, Tab } from './product-information-tabs.styled';

export { ProductInformationTabsStyled } from './product-information-tabs.styled';

interface ProductInformationTabsProps {
  overviewLabel?: string | null;
  documentsLabel?: string | null;
  detailsLabel?: string | null;
  intro?: string | null;
  documents?: ProductDetailsApiDocument[];
  detailsInfo?: string | null;
}

export const ProductInformationTabs: React.FC<ProductInformationTabsProps> = ({
  overviewLabel,
  intro,
  documentsLabel,
  documents = [],
  detailsLabel,
  detailsInfo,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const detailsArray: string[] = useMemo(() => {
    return detailsInfo?.split(/\r|\n/) ?? [];
  }, [detailsInfo]);

  function documentsMapper(document: ProductDetailsApiDocument, index: number) {
    return (
      <DownloadFileBox
        key={`Document-${index}`}
        url={document.url}
        label={document.name}
        size={document.size}
        filetype={document.filetype}
      />
    );
  }

  function detailsMapper(item: string, index: number) {
    return <li key={`Detail-Key-${index}`}>{item}</li>;
  }

  return (
    <ProductInformationTabsStyled>
      <Tabs>
        {intro && (
          <Tab $active={activeTab === 1} onClick={() => setActiveTab(1)}>
            <Label $active={activeTab === 1}>
              {' '}
              {overviewLabel}
            </Label>
          </Tab>
        )}
        {detailsInfo && (
          <Tab $active={activeTab === 3} onClick={() => setActiveTab(3)}>
            <Label $active={activeTab === 3}>{detailsLabel}</Label>
          </Tab>
        )}
        {!!documents?.length && (
          <Tab $active={activeTab === 2} onClick={() => setActiveTab(2)}>
            <Label $active={activeTab === 2}>{documentsLabel}</Label>
          </Tab>
        )}
      </Tabs>
      {intro && activeTab === 1 && (
        <Paragraph className="OverviewText">{intro}</Paragraph>
      )}
      {!!documents?.length && activeTab === 2 && (
        <>{documents?.map(documentsMapper)}</>
      )}

      {!!detailsArray?.length && activeTab === 3 && (
        <ul className="DetailInfo">{detailsArray.map(detailsMapper)}</ul>
      )}
    </ProductInformationTabsStyled>
  );
};
