import styled from 'styled-components';

import { colors, spacing } from '@hultafors/solidgear/helpers';

import { HTag } from '../HTag/HTag';

export const ContentPlugsTitleWrapper = styled.div`
  border-top: 1px solid ${colors.gray4};
  display: flex;
  width: '100%';
  align-items: center;
  justify-content: center;
  padding-top: ${spacing.large};
`;

export const ExploreMoreTitle = styled(HTag)`
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
`;
