import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

const arrowWidth = 16;

export const NoImage = styled.div`
  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-inline-start: 116px;
  }
`;

export const ProductSliderStyled = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;


  *:focus {
    outline: none;
  }

  .SliderAndContentWrapper {
    display: flex;
    flex-direction: row;
    block-size: 100%;
    inline-size: 100%;
  }

  .SliderAndCounterWrapper {
    position: relative;
    inline-size: 100%;
    block-size: 100%;

    button {
      display: none;

      &:hover {
        svg {
          path {
            stroke: ${colors.solidBlackHover};
          }
        }
      }
    }

    .ZoomIcon {
      position: absolute;
      inset-block-start: ${spacing.xsmall};
      inset-inline-end: ${spacing.xsmall};
    }

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      min-inline-size: 437px;
      padding-inline-start: ${spacing.small};

      button {
        display: block;
        position: absolute;
        inset-block-start: 0;
        z-index: 1;
        inset-inline-end: 0;
      }
    }
  }

  .ThumbNailWrapper {
    display: none;
    inline-size: 100%;
    min-inline-size: 100px;
    max-inline-size: 100px;
    block-size: 100px;

    > * {
      inline-size: 100%;
      block-size: 100%;
      position: relative;
      display: block;
      line-height: 0;
      cursor: pointer;

      &.Active {
        border: 1px solid ${colors.gray3};
      }
    }

    > * + * {
      margin-block-start: ${spacing.xsmall};
    }

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      display: block;
    }
  }

  .ImgWrapper {
    position: relative;
    display: flex;
    block-size: 100%;
    inline-size: 100%;
    pointer-events: none;
  }

  .Counter {
    display: flex;
    position: absolute;
    inset-inline-start: 0;
    inset-block-end: 0;
    color: ${colors.solidBlack};
    font-size: ${fontSizes.bodyS};
    line-height: ${lineHeights.bodyS};
    align-items: center;
    justify-content: center;
    block-size: auto;
    inline-size: 100%;
    padding-inline-start: ${spacing.small};
    padding-block-end: ${spacing.xsmall};

    .ActiveSlide {
      color: ${colors.solidBlack};
    }
  }

  .slick-slider {
    position: relative;
    display: block;
    block-size: 100%;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-prev,
  .slick-next {
    border: none;
    outline: 0;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 0;
    }
  }

  .slick-slider.article {
    .slick-prev,
    .slick-next {
      inline-size: ${spacing.small};
      block-size: ${spacing.small};
      position: absolute;
      z-index: 1;
      inset-block: calc(50% - ${arrowWidth / 2}px) -${spacing.block};
      background: url('/assets/gfx/product_slider_arrow.svg') center center
        no-repeat;
      background-position: center;
    }

    .slick-prev {
      background-size: ${arrowWidth}px;
      inset-inline-start: 0;
      transform: rotate(180deg);
      padding-inline-start: ${spacing.small};
    }

    .slick-next {
      background-size: ${arrowWidth}px;
      inset-inline-end: 0;
      padding-inline-end: ${spacing.small};
    }

    .ImgWrapper {
      background-color: ${colors.gray5};
      position: relative;
      block-size: 100%;

      /* img { */

      /*   max-width: 1024px; */

      /*   width: 100%; */

      /*   max-width: 100%; */

      /*   max-height: 800px; */

      /*   object-fit: cover; */

      /* } */
    }
  }

  .slick-list {
    position: relative;
    display: block;
    block-size: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    position: relative;
    inset-block-start: 0;
    inset-inline-start: 0;
    display: block;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    block-size: 100%;
    transform: translate3d(0, 0, 0);
  }

  .slick-track::before,
  .slick-track::after {
    display: table;
    content: '';
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: inline-start;
    block-size: 100%;
    min-block-size: 1px;

    > div {
      block-size: 100%;
    }
  }

  [dir='rtl'] .slick-slide {
    float: inline-end;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    block-size: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;
