import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { ImageFragment } from '@hultafors/solidgear/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H1 } from '../H1/H1';
import { HeroImage } from '../HeroImage/HeroImage';

import { HeroStyled } from './hero.styled';

interface HeroProps {
  title: string;
  imageMobile?: ImageFragment | null;
  imageTablet?: ImageFragment | null;
  imageDesktop?: ImageFragment | null;
  heroCtaTitle1?: string;
  heroCtaUrl1?: string;
  heroCtaTitle2?: string;
  heroCtaUrl2?: string;
  className?: string;
}

export const Hero: React.FC<HeroProps> = ({
  title = '',
  heroCtaTitle1 = '',
  heroCtaUrl1 = '',
  heroCtaTitle2 = '',
  heroCtaUrl2 = '',
  className,
  imageDesktop,
  imageTablet,
  imageMobile,
}) => {
  const { settings } = useGlobal();
  return (
    <HeroStyled $image={!!imageDesktop || !!imageTablet || !!imageMobile}>
      <HeroImage
        mobile={imageMobile}
        tablet={imageTablet}
        desktop={imageDesktop}
        priority={true}
      />
      <div className={`HeroContent ${className}`}>
        <Grid
          columns={[{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }]}
        >
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 4, start: 5 },
            ]}
          >
            <H1>{title}</H1>
            {(heroCtaTitle1 || heroCtaTitle2) && (
              <div className="HeroButtonWrapper">
                <span className="InnerButtonWrapper">
                  {heroCtaTitle1 && (
                    <ButtonLink
                      href={createUrl(heroCtaUrl1, settings) || ''}
                      className="White"
                    >
                      {heroCtaTitle1}
                    </ButtonLink>
                  )}
                  {heroCtaTitle2 && (
                    <ButtonLink
                      href={createUrl(heroCtaUrl2, settings) || ''}
                      className="White"
                    >
                      {heroCtaTitle2}
                    </ButtonLink>
                  )}
                </span>
              </div>
            )}
          </GridChild>
        </Grid>
      </div>
    </HeroStyled>
  );
};
