import dynamic from 'next/dynamic';

import { StyledProductDetailVideoPlayer } from './product-detail-video-player.styled';

const BynderVideo = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.BynderVideo),
);

interface ProductDetailVideoPlayerProps {
  videoId: string;
}
export const ProductDetailVideoPlayer: React.FC<
  ProductDetailVideoPlayerProps
> = ({ videoId }) => (
  <StyledProductDetailVideoPlayer>
    <BynderVideo videoId={videoId} />
  </StyledProductDetailVideoPlayer>
);
