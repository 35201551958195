import { GaugeStyled } from './Gauge.styled';
interface GaugeProps {
  soFar: number;
  toGo: number;
}

export const Gauge: React.FC<GaugeProps> = ({ soFar, toGo }) => (
  <GaugeStyled $soFar={soFar} $toGo={toGo} className="Gauge">
    <div className="SoFar" />
    <div className="ToGo" />
  </GaugeStyled>
);
