import { FC } from 'react';

import { filterSteps } from '@hultafors/solidgear/helpers';
import {
  FilterStep,
  SelectionValue,
  ShoeGuideQuery,
  ShoeGuideSelection,
  SolidgearProduct,
} from '@hultafors/solidgear/types';

import { H1 } from '../H1/H1';
import { H2 } from '../H2/H2';
import { H5 } from '../H5/H5';
import { Products } from '../products/products';
import { TextButton } from '../text-button/text-button';

import { ShoeGuideStyled } from './ShoeGuide.styled';

interface ResultProps {
  shoeGuide: ShoeGuideQuery['shoeGuide'];
  restart: () => void;
  selection: ShoeGuideSelection;
  rrpLabel: string;
  rrpIncludingVatLabel: string;
  perfectMatch: SolidgearProduct[];
  semiPerfectMatch: SolidgearProduct[];
}
/* For radio style selection */

export const Result: FC<ResultProps> = ({
  shoeGuide,
  restart,
  selection,
  rrpLabel,
  rrpIncludingVatLabel,
  perfectMatch,
  semiPerfectMatch,
}) => {
  const getEnvironmentSelection = () => {
    const result: any[] = [];
    const environment = [
      filterSteps.indoorOutdoor,
      filterSteps.dryWet,
      filterSteps.warmCold,
      filterSteps.cleanDirty,
    ];

    environment.forEach((item) => {
      if (!Array.isArray(selection[item.name])) {
        const castValue = selection[item.name] as SelectionValue;
        if (castValue.description) {
          result.push(castValue.description);
        }
      }
    });
    return result.join(', ');
  };

  const getSafetySelection = () => {
    const result: any[] = [];
    selection.safety.map((item) => {
      if (item.description) {
        result.push(item.description);
      }
    });

    return result.join(', ');
  };

  const getSelection = (filterStep: FilterStep) => {
    if (!Array.isArray(selection[filterStep.name])) {
      const castValue = selection[filterStep.name] as SelectionValue;
      if (castValue.description) {
        return castValue.description;
      }
    }

    return '';
  };

  const renderSelection = () => {
    const lacing = getSelection(filterSteps.lacing);
    const shoeType = getSelection(filterSteps.shoeType);
    const environment = getEnvironmentSelection();
    const safety = getSafetySelection();
    return (
      <div className="ChoicesContainer">
        {lacing && (
          <div className="ChoiceContainer">
            <H5>{shoeGuide?.typeOfLacing ?? 'Type of lacing'}</H5>
            <p>{lacing}</p>
          </div>
        )}
        {shoeType && (
          <div className="ChoiceContainer">
            <H5>{shoeGuide?.typeOfShoe ?? 'Type of shoe'}</H5>
            <p>{shoeType}</p>
          </div>
        )}
        {safety && (
          <div className="ChoiceContainer">
            <H5>{shoeGuide?.safetyFeatures ?? 'Safety features'}</H5>
            <p>{safety}</p>
          </div>
        )}
        {environment && (
          <div className="ChoiceContainer">
            <H5>{shoeGuide?.workingEnvironment ?? 'Working environment'}</H5>
            <p>{environment}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <ShoeGuideStyled>
      <div className="ResultContainer">
        <div className="RestartButtonContainer">
          <TextButton
            className="RestartButton"
            iconLeft={true}
            iconUrl="/assets/gfx/refresh.svg"
            onClick={restart}
          >
            {shoeGuide?.startOver ?? 'Restart'}
          </TextButton>
        </div>
        <div className="DoneContainer FadeOut">
          <img src="/assets/gfx/check-circle-orange.svg" alt="" />
          <H1>{shoeGuide?.done ?? 'Done!'}</H1>
        </div>
        <div className="ChoicesAndHeadingContainer">
          <H2>{shoeGuide?.yourChoices ?? 'Your choices'}</H2>
          {renderSelection()}
        </div>
        <div className="BestRecommendationsContainer">
          <H2>
            {shoeGuide?.hereAreYouRecommendations
            ?? 'Here are your recommendations'}
          </H2>
          <Products
            rrpLabel={rrpLabel}
            rrpIncludingVatLabel={rrpIncludingVatLabel}
            products={perfectMatch}
          />
        </div>
        {semiPerfectMatch && semiPerfectMatch.length > 0 && (
          <div className="OtherRecommendationsContainer">
            <H2>
              {shoeGuide?.moreRecommendations ?? 'More good recommendations'}
            </H2>
            <Products
              rrpLabel={rrpLabel}
              rrpIncludingVatLabel={rrpIncludingVatLabel}
              products={semiPerfectMatch}
            />
          </div>
        )}
      </div>
    </ShoeGuideStyled>
  );
};
