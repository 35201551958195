import styled, { css, CSSProperties } from 'styled-components';

import { fontSizes } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';
import { colors } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';

interface StyledParagraphProps {
  $center?: boolean;
  $bold?: boolean;
  $small?: boolean;
  $xSmall?: boolean;
  $color?: CSSProperties['color'];
  $hasLeftBorder?: boolean;
  $noMargin?: boolean;
}

export const StyledParagraph = styled.p<StyledParagraphProps>`
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.body};
  color: ${({ $color }) => $color ?? colors.solidBlack};
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};

  ${({ $bold }) =>
    $bold
    && css`
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
    `}

  ${({ $small }) =>
    $small
    && css`
      font-size: ${fontSizes.bodyS};
      line-height: ${lineHeights.bodyS};
    `}

  ${({ $xSmall }) =>
    $xSmall
    && css`
      font-size: ${fontSizes.bodyXS};
      line-height: ${lineHeights.bodyXS};
    `}

  ${({ $hasLeftBorder }) =>
    $hasLeftBorder
    && css`
      padding-left: ${spacing.xsmall};
      border-left: 2px solid;
    `}

  ${({ $noMargin }) =>
    $noMargin
    && css`
      margin: 0;
    `}
`;
