import Markdown from 'markdown-to-jsx';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { ContactInformationStyled } from './contact-information.styled';

interface ContactInformationProps {
  addressLabel?: string;
  addressBlock?: string;
  phoneLabel?: string;
  phoneBlock?: string;
  emailLabel?: string;
  emailBlock?: string;
  localContactLabel?: string;
  localContactBlock?: string;
}
export const ContactInformation: React.FC<ContactInformationProps> = ({
  addressLabel,
  addressBlock,
  phoneLabel,
  phoneBlock,
  emailLabel,
  emailBlock,
  localContactLabel,
  localContactBlock,
}) => {
  const renderAdressBlock = (title, address) => {
    if (!title && !address) return;
    // empty Markdown tag will throw a hissy fit
    const addressElement = address ? <Markdown>{address}</Markdown> : '';
    return renderInfoBlock(title, addressElement);
  };
  const renderPhoneNumbersBlock = (title, phoneNumbersString) => {
    if (!title && !phoneNumbersString) return;
    return renderInfoBlock(title, splitPhoneNumbers(phoneNumbersString));
  };
  const splitPhoneNumbers = (phoneNumbersString) => {
    const numbersArr = phoneNumbersString.split(/[\r\n]+/);
    if (numbersArr.length < 1) return '';
    return numbersArr.map((number, i) => (
      <a key={`nKey${i}`} href={`tel:${number}`}>
        {number}
      </a>
    ));
  };
  const renderEmailBlock = (title, email) => {
    if (!title && !email) return;
    const aTag = email ? <a href={`mailto:${email}`}>{email}</a> : '';
    return renderInfoBlock(title, aTag);
  };
  const renderInfoBlock = (title, info) => {
    return (
      <div className="TextBlock">
        {title && <h2>{title}</h2>}
        {info && info}
      </div>
    );
  };
  if (!addressLabel && !emailLabel) {
    return null;
  }
  return (
    <ContactInformationStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 4, start: 3 },
            { breakpoint: 'desktopMedium', columns: 3, start: 4 },
          ]}
        >
          {renderAdressBlock(addressLabel, addressBlock)}
          {renderPhoneNumbersBlock(phoneLabel, phoneBlock)}
          {renderEmailBlock(emailLabel, emailBlock)}
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 4, start: 8 },
            { breakpoint: 'desktopMedium', columns: 3, start: 7 },
          ]}
        >
          {renderAdressBlock(localContactLabel, localContactBlock)}
        </GridChild>
      </Grid>
    </ContactInformationStyled>
  );
};
