import styled from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';

interface RadioButtonStyledProps {
  $color?: string;
}

export const RadioButtonStyled = styled.div<RadioButtonStyledProps>`
  width: 100%;
  box-sizing: border-box;
  font-size: ${fontSizes.body};
  position: relative;
  background: ${colors.gray5};
  margin-bottom: ${spacing.small};

  &:hover {
    background: ${colors.gray4};
  }

  cursor: pointer;

  label {
    padding: ${spacing.small};
    inline-size: 100%;
    color: ${({ $color }) => ($color ? $color : colors.black)};
  }

  input[type='radio'] {
    margin-block-end: ${spacing.xsmall};
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    inset-inline-start: -9999px;
  }

  [type='radio']:checked + label {
    border: 1px solid ${colors.solidOrange};
  }

  [type='radio']:not(:checked) + label {
    border: 1px solid ${colors.gray4};
  }

  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;

    /* padding-left: ${spacing.regular}; */

    /* padding-bottom: ${spacing.xsmall}; */
    cursor: pointer;
    line-height: 16px;
    display: inline-block;
    color: ${({ $color }) => ($color ? $color : colors.black)};
  }

  [type='radio']:checked + label::before,
  [type='radio']:not(:checked) + label::before {
    content: '';
    position: absolute;
    inset-inline-start: ${spacing.small};
    inset-block-start: calc(50% - 16px / 2);
    block-size: 16px;
    inline-size: 16px;
    border: 1px solid ${({ $color }) => ($color ? $color : colors.gray4)};
    border-radius: 100%;
    background: ${({ $color }) => ($color ? $color : colors.white)};
  }

  [type='radio']:checked + label::after,
  [type='radio']:not(:checked) + label::after {
    content: '';
    inset-block-start: calc(50% - 8px / 2);
    inset-inline-start: 20px;
    inline-size: 8px;
    block-size: 8px;
    background: ${({ $color }) => ($color ? $color : colors.solidOrange)};
    position: absolute;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type='radio']:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0);
  }

  [type='radio']:checked + label::after {
    opacity: 1;
    transform: scale(1);
  }
`;
