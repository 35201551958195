import { useState } from 'react';

import { ImageFragment } from '@hultafors/solidgear/types';

import { Button } from '../button/button';
import { SubscriptionDialog } from '../SubscriptionDialog/SubscriptionDialog';

import { SubscribeText } from './subscribe-area.styled';
interface SubscribeAreaProps {
  subscribeInfoText?: string;
  subscribeButtonText?: string;
  userEmailFormUrl?: string;
  userEmailFormImage?: ImageFragment | null;
  closeLabel?: string;
}
export const SubscribeArea: React.FC<SubscribeAreaProps> = ({
  subscribeInfoText,
  subscribeButtonText,
  userEmailFormUrl,
  userEmailFormImage,
  closeLabel,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
  return (
    <>
      <SubscribeText>{subscribeInfoText}</SubscribeText>
      <Button onClick={toggleDialog} nature="gray">
        {subscribeButtonText}
      </Button>

      {isDialogOpen && (
        <SubscriptionDialog
          formUrl={userEmailFormUrl}
          image={userEmailFormImage}
          onHandleClose={toggleDialog}
          closeLabel={closeLabel}
        />
      )}
    </>
  );
};
