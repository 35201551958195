import Markdown from 'markdown-to-jsx';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H1 } from '../H1/H1';

import { FindRetailersHeroStyled } from './find-retailers-hero.styled';

interface FindRetailersHeroProps {
  title: string;
  description?: string;
}
export const FindRetailersHero: React.FC<FindRetailersHeroProps> = ({
  title,
  description,
}) => {
  return (
    <FindRetailersHeroStyled>
      <Grid
        className="hero"
        columns={[{ columns: 4 }, { breakpoint: 'largeMobile', columns: 12 }]}
        rowGap={24}
      >
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'largeMobile', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 4, start: 5 },
          ]}
        >
          <H1>{title}</H1>
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'largeMobile', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 4, start: 5 },
          ]}
        >
          {description && (
            <div className="HeroDescription">
              <Markdown options={{ forceBlock: true }}>{description}</Markdown>
            </div>
          )}
        </GridChild>
      </Grid>
    </FindRetailersHeroStyled>
  );
};
