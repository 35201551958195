import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

interface ContentHeroStyledProps {
  $splitHero?: boolean;
}

export const ContentHeroStyled = styled.div<ContentHeroStyledProps>`
  p {
    &.hero-description {
      font-style: normal;
      font-weight: ${fontWeights.fontMedium};
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.body};
      text-align: center;
    }

    &.bold {
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
    }
  }

  .hero-content {
    ${({ $splitHero }) =>
      $splitHero
      && css`
        max-width: calc(100vw - 40px);
        width: 1000px;
        margin-inline: auto;
      `}
    .HeroImage {
      position: relative;
      aspect-ratio: ${({ $splitHero }) => ($splitHero ? '16 / 9' : '1 / 1')};
      block-size: auto;

      @media screen and (min-width: ${breakpoints.tablet}) {
        aspect-ratio: 16 / 9;
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        aspect-ratio: ${({ $splitHero }) => !$splitHero && ' 12 / 4'};

        img {
          object-fit: ${({ $splitHero }) => !$splitHero && 'cover'};
        }
      }
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      ${({ $splitHero }) =>
        $splitHero
        && css`
          display: flex;
          align-items: center;
          justify-content: center;
          margin-inline: auto;
          max-width: 1000px;
          margin-top: 22px;

          div {
            inline-size: ${$splitHero && '100%'};
          }

          div:first-child > div {
            grid-column: span 12;
          }
        `}
    }
  }

  .noMaxWidth {
    max-inline-size: 100%;
  }

  .NotFoundView p {
    margin: 0;
  }

  .hero-description p {
    font-style: normal;
    font-weight: ${fontWeights.fontMedium};
    font-size: ${fontSizes.body};
    line-height: ${lineHeights.body};
    text-align: center;
  }

  .hero-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: ${spacing.small};
    padding-block: ${({ $splitHero }) =>
      $splitHero ? `${spacing.medium} 0` : `${spacing.smallMedium}`};

    &.ContentListView {
      padding-block-start: ${spacing.large};

      p {
        margin: 0;
        margin-block-start: ${spacing.regular};
        font-family: ${fontFamilies.fontRegular};
        font-weight: ${fontWeights.fontRegular};
        font-size: ${fontSizes.header3Content};
        line-height: ${lineHeights.header3Content};
        text-align: center;
      }
    }
  }

  .hero-title {
    &.contentView {
      font-style: normal;
      font-weight: ${fontWeights.fontMedium};
      font-size: ${fontSizes.header2Content};
      line-height: ${lineHeights.header2Content};
      text-align: center;
      margin: 0;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.header1Content};
        line-height: ${lineHeights.header1Content};
      }
    }

    &.contactView {
      font-style: normal;
      font-family: ${fontFamilies.fontHero};
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.header3};
      line-height: ${lineHeights.header3};
      text-align: center;
      margin: 0;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.header2};
        line-height: ${lineHeights.header2};
      }
    }

    &.NotFoundView {
      font-style: normal;
      font-family: ${fontFamilies.fontRegularBold};
      font-weight: ${fontWeights.fontMedium};
      font-size: ${fontSizes.header1Content};
      line-height: ${lineHeights.header3};
      text-align: center;
      margin: 0;
      margin-block-end: ${spacing.regular};
    }

    &.ContentListView {
      font-style: normal;
      font-family: ${fontFamilies.fontHero};
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.header3};
      line-height: ${lineHeights.header3};
      text-align: center;
      margin: 0;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.header2};
        line-height: ${lineHeights.header2};
      }
    }
  }

  .hero-description {
    &:last-child {
      margin-block-end: 0;
    }

    &.bold {
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
      margin-block-start: ${spacing.xxsmall};
    }
  }
`;
