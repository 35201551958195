import styled from 'styled-components';

export const SafetyClassIconsStyled = styled.div`
  display: flex;
  flex-direction: row;
  img {
    height: 48px;
    width: 48px;
    margin: 0;
    padding: 0;
  }
`;
