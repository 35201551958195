import * as Styled from './filter-bar.styled';
interface FilterBarProps {
  toggleFilter: (...args: any[]) => any;
  filterText: string;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  toggleFilter,
  filterText,
}) => {
  return (
    <Styled.FilterTextButton
      iconUrl="/assets/gfx/filter_24_white.svg"
      onClick={toggleFilter}
      iconLeft
    >
      {filterText}
    </Styled.FilterTextButton>
  );
};
