import { createGlobalStyle, css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

interface GlobalStyleProps {
  isLanguageSelectorVisible: boolean;
}

const cssFontWeights = css`
  --font-weight-light: ${fontWeights.fontLight};
  --font-weight-regular: ${fontWeights.fontRegular};
  --font-weight-medium: ${fontWeights.fontMedium};
`;

const cssFontSizes = css`
  --font-size-header-1: ${fontSizes.header1};
  --font-size-header-2: ${fontSizes.header2};
  --font-size-header-3: ${fontSizes.header3};
  --font-size-header-4: ${fontSizes.header4};
  --font-size-header-1-content: ${fontSizes.header1Content};
  --font-size-header-2-content: ${fontSizes.header2Content};
  --font-size-header-3-content: ${fontSizes.header3Content};
  --font-size-body: ${fontSizes.body};
  --font-size-body-s: ${fontSizes.bodyS};
  --font-size-body-xs: ${fontSizes.bodyXS};
  --font-size-h1: ${fontSizes.h1};
  --font-size-h1-mobile: ${fontSizes.h1mobile};
  --font-size-h2: ${fontSizes.h2};
  --font-size-h2-mobile: ${fontSizes.h2mobile};
  --font-size-h3: ${fontSizes.h3};
  --font-size-h4: ${fontSizes.h4};
  --font-size-h5: ${fontSizes.h5};
  --font-size-small: ${fontSizes.small};
  --font-size-x-small: ${fontSizes.xsmall};
  --font-size-super-small${fontSizes.supersmall}
`;

const cssLineHeights = css`
  --line-height-body: ${lineHeights.body};
  --line-height-body-s: ${lineHeights.bodyS};
  --line-height-body-xs: ${lineHeights.bodyXS};
  --line-height-header-1: ${lineHeights.header1};
  --line-height-header-1-content: ${lineHeights.header1Content};
  --line-height-header-2: ${lineHeights.header2};
  --line-height-header-2-content: ${lineHeights.header2Content};
  --line-height-header-3: ${lineHeights.header3};
  --line-height-header-3-content: ${lineHeights.header3Content};
  --line-height-header-4: ${lineHeights.header4};
  --line-height-header-5: ${lineHeights.header5};
  --line-height-l: ${lineHeights.l};
  --line-height-m: ${lineHeights.m};
  --line-height-s: ${lineHeights.s};
  --line-height-xs: ${lineHeights.xs};
`;

const cssColors = css`
  --color-solid-orange: ${colors.solidOrange};
  --color-solid-orange-hover: ${colors.solidOrangeHover};
  --color-ok: ${colors.ok};
  --color-warning: ${colors.warning};
  --color-active: ${colors.active};
  --color-s1: ${colors.s1};
  --color-s1p: ${colors.s1p};
  --color-s2p: ${colors.s2p};
  --color-s3: ${colors.s3};
  --color-o1: ${colors.o1};
  --color-o2: ${colors.o2};
  --color-gray6: ${colors.gray6};
  --color-gray5: ${colors.gray5};
  --color-gray4: ${colors.gray4};
  --color-gray3: ${colors.gray3};
  --color-gray2: ${colors.gray2};
  --color-gray1: ${colors.gray1};
  --color-solid-black: ${colors.solidBlack};
  --color-solid-black-hover: ${colors.solidBlackHover};
  --color-box-shadow-transparent: ${colors.boxshadowTransparent};
  --color-black: ${colors.black};
  --color-white: ${colors.white};
  --color-overlay-transparent: ${colors.overlayTransparent};
`;

const cssSpacing = css`
  --spacing-block: ${spacing.block};
  --spacing-full-nav-height-with-lang: ${spacing.fullNavHeightInclLangSelector};
  --spacing-large: ${spacing.large};
  --spacing-medium: ${spacing.medium};
  --spacing-mega: ${spacing.mega};
  --spacing-menu-height: ${spacing.menuHeight};
  --spacing-mobile-top: ${spacing.mobileTop};
  --spacing-page-padding: ${spacing.pagePadding};
  --spacing-page-padding-mobile: ${spacing.pagePaddingMobile};
  --spacing-regular: ${spacing.regular};
  --spacing-small: ${spacing.small};
  --spacing-small-medium: ${spacing.smallMedium};
  --spacing-xl: ${spacing.xl};
  --spacing-xlarge: ${spacing.xlarge};
  --spacing-xsmall: ${spacing.xsmall};
  --spacing-xxlarge: ${spacing.xxlarge};
  --spacing-xxsmall: ${spacing.xxsmall};
`;

const defaults = css`
  --color-text: ${colors.solidBlack};
  --color-text-subdued: ${colors.solidBlackHover};
  --color-border: ${colors.border};
  --color-dimmed: ${colors.gray5};
  --color-link: ${colors.solidBlack};
  --color-link-hover: ${colors.solidBlackHover};
`;

const cssVariables = css`
  :root {
    --header-height: 4rem;
    --text-decoration-link: none;
    --text-decoration-link-hover: underline;
    ${cssColors}
    ${cssFontSizes}
    ${cssFontWeights}
    ${cssSpacing}
    ${cssLineHeights}
    ${defaults}
  }
`;

const globalStyle = css<GlobalStyleProps>`
  html {
    font-size: 16px;
    min-height: 100vh;
    -webkit-overflow-scrolling: touch;
  }

  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    font-weight: ${fontWeights.fontRegular};
    padding-top: var(--header-height);

    @media (min-width: ${breakpoints.desktop}) {
      padding-top: var(--header-height);
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--color-text);
  }

  #root {
    min-height: 100vh;
  }

  img {
    max-width: 100%;
  }

  .no-js img.lazyload {
    display: none;
  }

  .map {
    position: relative;
    width: 100%;
  }
`;

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${cssVariables};
  ${globalStyle};
`;
