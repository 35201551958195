import {
  ListApiFilter,
  ListApiFilterValue,
  ParsedProductsApiResponse,
} from '@hultafors/shared/types';

import {
  FilterSteps,
  ShoeGuideSelection,
  SolidgearProduct,
  Step,
} from '@hultafors/solidgear/types';

export const getShoeGuideFilterParams = (
  filters: ListApiFilter[]
): URLSearchParams => {
  const params = new URLSearchParams();
  let i = 0;

  filters.forEach(({ id: AttrId, values }) => {
    values.forEach(({ id: ValueId }) => {
      params.set(`f[${i}].AttrId`, `${AttrId}`);
      params.set(`f[${i}].ValueId`, `${ValueId}`);
      i += 1;
    });
  });
  return params;
};

export const filterSteps: FilterSteps = {
  cleanDirty: { id: 935, name: 'cleanDirty' },
  dryWet: { id: 933, name: 'dryWet' },
  indoorOutdoor: { id: 932, name: 'indoorOutdoor' },
  lacing: { id: 937, name: 'lacing' },
  safety: { id: 936, name: 'safety' },
  shoeType: { id: 938, name: 'shoeType' },
  warmCold: { id: 934, name: 'warmCold' },
};

export const getFilters = (
  filters: ListApiFilter[],
  filterCategory: string
) => {
  switch (filterCategory) {
    case filterSteps.indoorOutdoor.name:
      return getIndoorOutdoor(filters);
    case filterSteps.dryWet.name:
      return getDryWetFilters(filters);
    case filterSteps.warmCold.name:
      return getWarmColdFilters(filters);
    case filterSteps.cleanDirty.name:
      return getCleanDirtyFilters(filters);
    case filterSteps.safety.name:
      return getSafetyFilters(filters);
    case filterSteps.lacing.name:
      return getLacingFilters(filters);
    case filterSteps.shoeType.name:
      return getShoeTypeFilters(filters);
    default:
      return null;
  }
};

export const getFilterTitle = (
  filters: ListApiFilter[],
  currentStepName: string
) => {
  let result = null;
  switch (currentStepName) {
    case filterSteps.indoorOutdoor.name:
      result = filters.find((x) => x.id === filterSteps.indoorOutdoor.id);
      break;
    case filterSteps.dryWet.name:
      result = filters.find((x) => x.id === filterSteps.dryWet.id);
      break;
    case filterSteps.warmCold.name:
      result = filters.find((x) => x.id === filterSteps.warmCold.id);
      break;
    case filterSteps.cleanDirty.name:
      result = filters.find((x) => x.id === filterSteps.cleanDirty.id);
      break;
    case filterSteps.safety.name:
      result = filters.find((x) => x.id === filterSteps.safety.id);
      break;
    case filterSteps.lacing.name:
      result = filters.find((x) => x.id === filterSteps.lacing.id);
      break;
    case filterSteps.shoeType.name:
      result = filters.find((x) => x.id === filterSteps.shoeType.id);
      break;
    default:
      break;
  }
  if (result && result.label) {
    return result.label;
  }
  return '';
};

const getIndoorOutdoor = (filters: ListApiFilter[]) => {
  const result = filters.find((x) => x.id === filterSteps.indoorOutdoor.id);
  if (result && result.values.length) {
    return addParentToItem(result);
  }
  return [];
};

const getDryWetFilters = (filters: ListApiFilter[]) => {
  const result = filters.find((x) => x.id === filterSteps.dryWet.id);

  if (result && result.values.length) {
    return addParentToItem(result);
  }
  return [];
};

const getWarmColdFilters = (filters: ListApiFilter[]) => {
  const result = filters.find((x) => x.id === filterSteps.warmCold.id);

  if (result && result.values.length) {
    return addParentToItem(result);
  }
  return [];
};

const getCleanDirtyFilters = (filters: ListApiFilter[]) => {
  const result = filters.find((x) => x.id === filterSteps.cleanDirty.id);

  if (result && result.values.length) {
    return addParentToItem(result);
  }
  return [];
};

const getSafetyFilters = (filters: ListApiFilter[]) => {
  const result = filters.find((x) => x.id === filterSteps.safety.id);

  if (result && result.values.length) {
    return addParentToItem(result);
  }
  return [];
};

const getLacingFilters = (filters: ListApiFilter[]) => {
  const result = filters.find((x) => x.id === filterSteps.lacing.id);

  if (result && result.values.length) {
    return addParentToItem(result);
  }
  return [];
};

const getShoeTypeFilters = (filters: ListApiFilter[]) => {
  const result = filters.find((x) => x.id === filterSteps.shoeType.id);

  if (result && result.values.length) {
    return addParentToItem(result);
  }
  return [];
};

const addParentToItem = (result: ListApiFilter): ListApiFilterValue[] => {
  result.values.forEach((value) => {
    value.parentId = result.id;
  });
  return result.values;
};

// The order of the guide by category
export const resolveStep = (stepNumber: number) => {
  const resolvedStep: Step = {
    name: null,
    number: stepNumber,
  };
  switch (stepNumber) {
    case 0:
      resolvedStep.name = 'start';
      break;
    case 1:
      resolvedStep.name = filterSteps.indoorOutdoor.name;
      break;
    case 2:
      resolvedStep.name = filterSteps.dryWet.name;
      break;
    case 3:
      resolvedStep.name = filterSteps.warmCold.name;
      break;
    case 4:
      resolvedStep.name = filterSteps.cleanDirty.name;
      break;
    case 5:
      resolvedStep.name = filterSteps.safety.name;
      break;
    case 6:
      resolvedStep.name = filterSteps.lacing.name;
      break;
    case 7:
      resolvedStep.name = filterSteps.shoeType.name;
      break;
    case 8:
      resolvedStep.name = 'result';
      break;
  }

  return resolvedStep;
};

export const isSingleChoice = (currentStep: Step) => {
  if (
    currentStep.name === filterSteps.indoorOutdoor.name ||
    currentStep.name === filterSteps.dryWet.name ||
    currentStep.name === filterSteps.warmCold.name ||
    currentStep.name === filterSteps.cleanDirty.name ||
    currentStep.name === filterSteps.lacing.name ||
    currentStep.name === filterSteps.shoeType.name
  ) {
    return true;
  }
  return false;
};

export const isMultiChoice = (currentStep: Step) => {
  if (currentStep.name === filterSteps.safety.name) {
    return true;
  }
  return false;
};

export const resolveResult = (
  productData: ParsedProductsApiResponse,
  filteredProducts: any,
  maxItemsToShow: number
) => {
  // Select total of 5 products, always select best matching products in result to fill total result

  const products = productData.items;

  const lessMatchProducts = [];

  let totalMatches = 0;

  let perfectMatch: SolidgearProduct[] = [];
  let notInPerfectList: SolidgearProduct[] = [];

  // pager only for perfect matches, used when result is greated than MAX_MATCHES_TO_SHOW
  const loadMorePaging = {
    itemCount: productData.paging.itemCount,
    pageNumber: 1,
    pageSize: maxItemsToShow,
  };
  let semiPerfectMatch: SolidgearProduct[] = [];
  let lessPerfectMatch: SolidgearProduct[] = [];

  if (products.length) {
    if (products.length > maxItemsToShow) {
      // pick max matches if available
      perfectMatch = products.slice(0, maxItemsToShow);
    } else {
      perfectMatch = products;
    }

    totalMatches += products.length;
  }

  if (totalMatches < maxItemsToShow) {
    if (filteredProducts.length > 1) {
      const semiMatchedFiltered =
        filteredProducts[filteredProducts.length - 1].items;

      notInPerfectList = [];

      semiMatchedFiltered.map((item: SolidgearProduct) => {
        // make sure we dont add products that are in perfect match list
        if (!perfectMatch.find((x) => x.sku === item.sku)) {
          notInPerfectList.push(item);
        }
      });
      semiPerfectMatch = notInPerfectList.slice(
        0,
        maxItemsToShow - totalMatches
      );
      // totalMatches += semiMatchProducts.length;
      totalMatches += semiPerfectMatch.length;
    }
  }

  if (totalMatches < maxItemsToShow) {
    let lessMatchedFiltered = [];

    if (filteredProducts.length > 2) {
      lessMatchedFiltered = filteredProducts[filteredProducts.length - 2].items;
    }

    if (filteredProducts.length === 1) {
      // we only got one result, pretty crappy guide :)
      lessMatchedFiltered = filteredProducts[0].items;
    }

    if (lessMatchedFiltered.length > 0) {
      notInPerfectList = [];

      lessMatchedFiltered.map((item: SolidgearProduct) => {
        // make sure we dont add products that are in perfect or semi perfect match list
        const inPerfectMatch = perfectMatch.find((x) => x.sku === item.sku);
        const inSemiPerfectMatch = semiPerfectMatch.find(
          (x) => x.sku === item.sku
        );
        if (!inPerfectMatch && !inSemiPerfectMatch) {
          notInPerfectList.push(item);
        }
      });

      // TODO loop back in time to make sure we have at least 5 if other ones are empty

      lessPerfectMatch = notInPerfectList.slice(
        0,
        maxItemsToShow - totalMatches
      );
      totalMatches += lessMatchProducts.length;
    }
  }

  return {
    lessPerfectMatch,
    loadMorePaging,
    perfectMatch,
    semiPerfectMatch,
  };
};

export const initSelection: ShoeGuideSelection = {
  cleanDirty: { id: 0 },
  dryWet: { id: 0 },
  filters: [],
  indoorOutdoor: { id: 0 },
  lacing: { id: 0 },
  safety: [],
  shoeType: { id: 0 },
  warmCold: { id: 0 },
};

export const isStepValid = (
  currentStep: Step,
  selection: ShoeGuideSelection
) => {
  let isValid = false;
  switch (currentStep.name) {
    case filterSteps.safety.name:
      isValid =
        currentStep.name === 'safety' && selection[currentStep.name].length > 0;
      break;
    case filterSteps.indoorOutdoor.name:
    case filterSteps.dryWet.name:
    case filterSteps.warmCold.name:
    case filterSteps.cleanDirty.name:
    case filterSteps.lacing.name:
    case filterSteps.shoeType.name:
      isValid =
        currentStep.name === 'shoeType' && selection[currentStep.name].id > 0;
      break;
    default:
      // start, result
      isValid = true;
      break;
  }
  return isValid;
};
