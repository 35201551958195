import { useMemo } from 'react';

import {
  OfficeFragment,
  OfficesBlockFragment,
} from '@hultafors/solidgear/types';

import { ContactList } from '../contact-list/contact-list';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H2 } from '../H2/H2';

import { ContactStoresStyled } from './contact-stores.styled';
const TYPES_OF_OFFICES = ['Sales office', 'Export markets'];

interface ContactStoresProps {
  offices?: OfficeFragment[];
  localWebLabel?: string;
  viewOnMapLabel?: string;
  salesOfficeLabel?: string;
  exportMarketsLabel?: string;
}
export const ContactStores: React.FC<
  ContactStoresProps | OfficesBlockFragment
> = ({
  offices = [],
  localWebLabel,
  viewOnMapLabel,
  salesOfficeLabel,
  exportMarketsLabel,
}) => {
  const salesOffices: OfficeFragment[] = useMemo(() => {
    return (
      offices?.filter((item) => item.typeOfOffice !== TYPES_OF_OFFICES[1]) || []
    );
  }, [offices]);
  const exportOffices: OfficeFragment[] = useMemo(() => {
    return (
      offices?.filter((item) => item.typeOfOffice === TYPES_OF_OFFICES[1]) || []
    );
  }, [offices]);

  if (!offices.length || !salesOfficeLabel) {
    return null;
  }

  return (
    <ContactStoresStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktopMedium', columns: 6, start: 4 },
          ]}
        >
          {salesOfficeLabel && <H2>{salesOfficeLabel}</H2>}
          <ContactList
            offices={salesOffices}
            localWebLabel={localWebLabel}
            viewOnMapLabel={viewOnMapLabel}
          />
          {exportMarketsLabel && <H2>{exportMarketsLabel}</H2>}
          <ContactList
            offices={exportOffices}
            localWebLabel={localWebLabel}
            viewOnMapLabel={viewOnMapLabel}
          />
        </GridChild>
      </Grid>
    </ContactStoresStyled>
  );
};
