import { SectionStyled } from './section.styled';
interface SectionProps {
  style?: React.CSSProperties;
  className?: string;
  marginTop?: boolean;
  hide?: boolean;
  children?: React.ReactNode;
}
export const Section: React.FC<SectionProps> = ({
  children,
  style,
  className,
  marginTop,
  hide,
}) => {
  return (
    <SectionStyled
      className={className}
      style={style}
      $marginTop={marginTop}
      $hide={hide}
    >
      {children}
    </SectionStyled>
  );
};
