import { StyledImage } from './Image.styled';
interface ImageProps {
  caption?: string;
  className?: string;
  sizes?: string;
  src: string;
  srcset?: string;
  alt?: string;
}

export const Image: React.FC<ImageProps> = ({
  caption,
  className,
  src,
  srcset,
  sizes = 'auto',
  alt = '',
}) => {
  return (
    <StyledImage
      alt={caption || alt}
      src={src}
      srcSet={srcset}
      sizes={sizes}
      title={caption}
      className={className}
    />
  );
};
