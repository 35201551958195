import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontSizes } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';

export const RelatedProductsSliderStyled = styled.div`
  .sliderWrapper {
    position: relative;
    inline-size: 100%;
    block-size: 100%;
    padding: 0;
  }

  .slick-list {
    position: relative;
    display: block;
    block-size: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    padding-inline-start: ${spacing.small};
  }

  .onLastSlide {
    .slick-list {
      padding-inline-start: 10%;
    }
  }

  .ImgWrapper {
    position: relative;
    display: flex;
    block-size: 100%;
    inline-size: 100%;
  }

  .Text {
    display: flex;
    flex-direction: row;
    block-size: auto;
    font-size: ${fontSizes.small};
    line-height: 1.375rem;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    @media screen and (min-width: ${breakpoints.largeMobile}) {
      margin-block-start: ${spacing.small};
    }

    &.hidden {
      opacity: 0;
    }

    p {
      margin: ${spacing.small} 0;

      @media screen and (min-width: ${breakpoints.largeMobile}) {
        margin: 0;
        inline-size: 60%;
      }
    }
  }

  .slick-slider {
    position: relative;
    display: block;
    block-size: 100%;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-slider.article {
    .ImgWrapper {
      display: flex !important;
      justify-content: center;
      background-color: #fff;

      img {
        inline-size: 100%;
        max-inline-size: 100%;
        max-block-size: 800px;
        object-fit: cover;
      }
    }
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    position: relative;
    inset-block-start: 0;
    inset-inline-start: 0;
    display: block;
    margin-block-end: 0;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    block-size: 100%;
    transform: translate3d(0, 0, 0);
  }

  .slick-track::before,
  .slick-track::after {
    display: table;
    content: '';
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: inline-start;
    block-size: 100%;
    min-block-size: 1px;
    padding-inline-end: 16px;
  }

  [dir='rtl'] .slick-slide {
    float: inline-end;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    block-size: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;
