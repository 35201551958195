import dynamic from 'next/dynamic';

import { ProductFilter, ProductFilterValue } from '@hultafors/shared/types';

import {
  Checkbox,
  CheckboxLabel,
  FilterList,
  FilterStyled,
} from './filter.styled';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionOld),
);
const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItemOld),
);

interface FilterProps {
  filterData: ProductFilter[];
  filterChange({ AttrId, ValueId }: { AttrId: string; ValueId: string }): void;
  collapseAllText?: string;
}

export const Filter: React.FC<FilterProps> = ({ filterData, filterChange }) => {
  const rootFilterMapper = ({ values, label = '', id }: ProductFilter) => {
    const key = `Filter-${id}`;
    function filterMapper(filter: ProductFilterValue) {
      const key = `Filter-${id}-${filter.id}}`;
      const onChange = () => {
        filterChange({
          AttrId: `${id}`,
          ValueId: `${filter.id}`,
        });
      };
      return (
        <CheckboxLabel key={key}>
          <Checkbox
            id={`filter-${filter.id}`}
            name={filter.description}
            value=""
            checked={filter.active}
            onChange={onChange}
          />
          <span>{filter.description}</span>
        </CheckboxLabel>
      );
    }
    return (
      <AccordionItem label={label} key={key} plus>
        <FilterList>
          {' '}
          {values.map(filterMapper)}
        </FilterList>
      </AccordionItem>
    );
  };

  return (
    <FilterStyled>
      <Accordion allowMultipleExpanded allowZeroExpanded>
        {filterData.map(rootFilterMapper)}
      </Accordion>
    </FilterStyled>
  );
};
