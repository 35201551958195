import { FieldWrapper, FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { Label } from '../Label/Label';

import { StyledSelect, StyledSelectContainer } from './select.styled';
interface SelectProps {
  id: string;
  inline?: boolean;
  options?: string[];
  selected: string;
  label: string;
  wrapperProps?: FieldWrapperProps;
  handleChange?: React.ChangeEventHandler<HTMLSelectElement>;
  children?: React.ReactNode;
}

export const Select: React.FC<SelectProps> = ({
  children,
  label,
  id,
  inline,
  options = [],
  selected,
  handleChange,
  wrapperProps,
}) => {
  return (
    <FieldWrapper inline={inline} {...wrapperProps}>
      <StyledSelectContainer>
        <Label htmlFor={id}>{label}</Label>
        <StyledSelect id={id} value={selected} onChange={handleChange}>
          {/* If children are provided, they will take
          precedence over the "options"prop */}
          {children
          ?? options.map((option) => (
            <option id={option} value={option} key={`select.${id}.${option}`}>
              {option}
            </option>
          ))}
        </StyledSelect>
      </StyledSelectContainer>
    </FieldWrapper>
  );
};
