import DefMarkdown from 'markdown-to-jsx';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

import { Grid as DefGrid } from '../grid/grid';
import { HTag as HTagComponent } from '../HTag/HTag';

export const Grid = styled(DefGrid)`
  width: 100%;
`;

export const Markdown = styled(DefMarkdown)`
  p:last-child {
    margin-block-end: 0;
  }
`;

export const TextBlockStyled = styled.div<{
  $noPad?: boolean;
  $smallerText?: boolean;
}>`
  display: flex;
  margin: ${spacing.small} auto;
  padding: ${({ $noPad }) => ($noPad ? null : `0 ${spacing.small}`)};

  ${({ $smallerText }) =>
    $smallerText
    && css`
      h3 {
        font-style: normal;
        font-weight: ${fontWeights.fontMedium};
        font-family: ${fontFamilies.fontRegularBold};
        font-size: ${fontSizes.body};
        line-height: ${lineHeights.body};
        margin: 0 0 ${spacing.xsmall} 0;
      }

      p,
      span,
      a {
        text-decoration: none;
        font-style: normal;
        font-weight: ${fontWeights.fontRegular};
        font-size: ${fontSizes.body};
        line-height: ${lineHeights.body};
        margin: 0;
      }

      a {
        display: block;

        &:hover {
          color: ${colors.solidBlackHover};
        }
      }
    `}

  @media all and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.regular} 0;
  }
`;

export const HTag = styled(HTagComponent)`
  font-family: ${fontFamilies.fontHero};
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontRegular};
  margin: ${spacing.small} 0;
  text-transform: uppercase;
`;
