import { CSSProperties } from 'styled-components';

import {
  AlertPlacement,
  AlertSize,
  AlertStatus,
  StyledAlert,
} from './Alert.styled';
interface AlertProps {
  status?: AlertStatus;
  size?: AlertSize;
  position?: CSSProperties['position'];
  placement?: AlertPlacement;
}
export const Alert: React.FC<AlertProps> = ({
  size = 'medium',
  status = 'info',
  position = 'relative',
  placement,
}) => {
  return (
    <StyledAlert
      $size={size}
      $status={status}
      $position={position}
      $placement={placement}
    />
  );
};
