import styled from 'styled-components';

import { spacing } from '@hultafors/solidgear/helpers';

export const GuideButtonStyled = styled.div`
  display: flex;
  margin-top: ${spacing.medium};

  button {
    margin: auto;
  }
`;
