import { Component } from 'react';

import { Paragraph } from '../paragraph/paragraph';
import { TextButton } from '../text-button/text-button';

import { CloseButton, CloseIcon, SlideInStyled } from './slide-in.styled';

interface SlideInProps {
  isOpen: boolean;
  toggle: (...args: any[]) => any;
  headerText?: string;
  headerExtra?: string;
  headerIcon?: string;
  headerClick?: (...args: any[]) => any;
  clearText?: string;
  clearAll?: (...args: any[]) => any;
  padContent?: boolean;
  isMenu?: boolean;
  isCart?: boolean;
  isFilter?: boolean;
  fromLeft?: boolean;
  fromTop?: boolean;
  small?: boolean;
  partnerPortalUrl?: string;
  partnerLabel?: string;
  iconLeft?: boolean;
  isSearch?: boolean;
  isGuide?: boolean;
  langSelectorChooseLanguageLabel?: string;
  settings?: object;
  itemLength?: number;
  closeLabel?: string | null;
  children?: React.ReactNode;
}
// TODO rebuild to func component
export class SlideIn extends Component<SlideInProps> {
  node: HTMLElement | null = null;
  setNode: React.LegacyRef<HTMLDivElement> = (element) => {
    this.node = element;
  };

  constructor(props: SlideInProps) {
    super(props);
    this.node = null;
    this.setNode = (element) => {
      this.node = element;
    };
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  override componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.handleOnKeyDown, false);
  }

  override componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.handleOnKeyDown, false);
  }

  handleClick(event: MouseEvent) {
    if (this.node === event.target) {
      this.props.toggle();
    }
  }

  handleOnKeyDown(event: KeyboardEvent) {
    // esc key
    if (event.keyCode === 27) {
      if (this.props.isOpen) {
        this.props.toggle();
      }
    }
  }

  override render() {
    const {
      isOpen,
      toggle,
      children,
      headerText,
      headerExtra,
      clearText,
      clearAll,
      headerIcon,
      isMenu,
      partnerPortalUrl,
      partnerLabel,
      itemLength,
      headerClick,
      iconLeft,

      closeLabel,
    } = this.props;
    return (
      <SlideInStyled
        $fromTop={this.props.fromTop}
        $fromLeft={this.props.fromLeft}
        $small={this.props.small}
        $isSearch={this.props.isSearch}
        $isFilter={this.props.isFilter}
        $padContent={this.props.padContent}
      >
        <div
          className={`SlideInBackground ${isOpen ? 'active' : ''}`}
          ref={this.setNode}
        >
          <div className={`SlideInInside ${isOpen ? 'active' : ''}`}>
            <div className="SlideInHeader">
              <div className={isMenu ? 'HeaderText isMenu' : 'HeaderText'}>
                {isMenu
                  ? (
                    <>
                      <CloseButton
                        aria-label={closeLabel || 'close'}
                        onClick={(e) => toggle(e)}
                      >
                        <CloseIcon
                          src="/assets/gfx/hamburger_close.svg"
                          alt={closeLabel || 'close'}
                          width="24px"
                          height="24px"
                        />
                      </CloseButton>
                      {partnerPortalUrl
                        ? (
                          <div className="PartnerLogin">
                            <Paragraph>
                              <a
                                href={partnerPortalUrl || ''}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="PartnerLoginLink"
                              >
                                {partnerLabel}
                                <img
                                  src="/assets/gfx/hultafors_partner_login.svg"
                                  alt="Partner login"
                                  width="24px"
                                  height="24px"
                                />
                              </a>
                            </Paragraph>
                          </div>
                          )
                        : (
                            ''
                          )}
                    </>
                    )
                  : (
                    <>
                      {headerClick
                        ? (
                          <TextButton
                            iconLeft={iconLeft}
                            iconUrl={headerIcon}
                            onClick={headerClick}
                          >
                            {headerText}
                          </TextButton>
                          )
                        : (
                          <>
                            {headerIcon && (
                              <img
                                src={headerIcon}
                                className="HeaderIcon"
                                alt="icon"
                              />
                            )}
                            <span>{headerText}</span>
                            {headerExtra && (
                              <span className="Extras">{`${headerExtra}`}</span>
                            )}
                          </>
                          )}
                    </>
                    )}
              </div>

              <div className="SlideInExit">
                {clearAll && clearText && !!itemLength && (
                  <span onClick={clearAll} className="Clear" role="none">
                    <span>{clearText}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <path d="M5 2V1h6v1h4v1H1V2z" />
                      <path d="M4.5 5h1v7.9661033h-1z" />
                      <path d="M10.5 5h1v7.9661033h-1z" />
                      <path d="M7.5 5h1v7.9661033h-1z" />
                      <path d="M13.5 14.5V5h1v10.5h-13V5h1v9.5z" />
                    </svg>
                  </span>
                )}

                {isMenu
                  ? (
                    <span />
                    )
                  : (
                    <CloseButton
                      aria-label={closeLabel || ' close'}
                      onClick={(e) => toggle(e)}
                    >
                      <CloseIcon
                        src="/assets/gfx/hamburger_close.svg"
                        alt={closeLabel}
                        width="24px"
                        height="24px"
                      />
                    </CloseButton>
                    )}
              </div>
            </div>

            <div
              className={
                this.props.isCart ? 'SlideInBody isCart' : 'SlideInBody'
              }
            >
              {children}
            </div>
          </div>
        </div>
      </SlideInStyled>
    );
  }
}
