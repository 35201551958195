import Markdown from 'markdown-to-jsx';

import { ImageFragment } from '@hultafors/solidgear/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HeroImage } from '../HeroImage/HeroImage';

import {
  DividerLine,
  DividerSection,
  HeroImageWrapper,
  ProductListHeaderStyled,
} from './product-list-header.styled';
interface ProductListHeaderProps {
  title?: string;
  category?: string;
  description?: string;
  imageMobile?: ImageFragment | null;
  imageTablet?: ImageFragment | null;
  imageDesktop?: ImageFragment | null;
}

export const ProductListHeader: React.FC<ProductListHeaderProps> = ({
  title,
  category,
  description,
  imageMobile,
  imageTablet,
  imageDesktop,
}) => {
  return (
    <ProductListHeaderStyled>
      <Grid className="Grid">
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'tablet', columns: 5, start: 8 },
            { breakpoint: 'desktop', columns: 5, start: 8 },
          ]}
        >
          {(imageDesktop || imageTablet || imageMobile) && (
            <HeroImageWrapper>
              <HeroImage
                mobile={imageMobile}
                tablet={imageTablet}
                desktop={imageDesktop}
                priority={true}
              />
            </HeroImageWrapper>
          )}
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'tablet', columns: 6, start: 1 },
          ]}
        >
          <div className="Content">
            {category && (
              <h2 className={`Category ${category ? 'Line' : ''}`}>
                {category ? category : ''}
              </h2>
            )}
            {title && <h1>{title}</h1>}
            {description && (
              <Markdown className="Markdown" options={{ forceBlock: true }}>
                {description}
              </Markdown>
            )}
          </div>
        </GridChild>
      </Grid>
      <DividerSection>
        <DividerLine />
      </DividerSection>
    </ProductListHeaderStyled>
  );
};
