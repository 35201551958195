import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

import { Input } from '../Input/Input';

interface TallProps {
  $tall?: boolean;
}

interface ShowProps {
  $show?: boolean;
}

export const SearchInputStyled = styled.span<TallProps & ShowProps>`
  width: 100%;
`;

export const SearchInputFormWrapper = styled.div<TallProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $tall }) => ($tall ? '150px' : 'auto')};
  width: 500px;
`;

export const SearchInputForm = styled.form`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const SearchInput = styled(Input)`
  width: 100%;
  max-width: 500px;
  margin: 0;

  input[type='text'] {
    margin: 0;
    padding: 0 40px 0 20px;
    border-radius: 24px;
    font-size: 16px;
    line-height: 3rem;
    color: ${colors.solidBlack};
    background-color: ${colors.white};
    border: ${colors.solidBlack};
  }

  input:placeholder-shown {
    border-color: ${colors.solidBlack};
  }

  input::placeholder {
    color: ${colors.solidBlack};
  }
`;

const buttonStyles = css`
  appearance: none;
  border: 0;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
`;

export const SearchInputChildren = styled.div`
  margin-block-end: ${spacing.small};
`;

export const ResultText = styled.p`
  font-family: ${fontFamilies.fontRegular};
  line-height: ${lineHeights.bodyS};
  margin-block: ${spacing.regular} 0;
  font-weight: ${fontWeights.fontMedium};
  font-size: ${fontSizes.body};
  text-align: center;

  &:empty {
    display: none;
  }
`;

export const ClearButton = styled.button`
  ${buttonStyles};
  right: 38px;
  transition: opacity 0.1s ease-out;

  svg {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
`;

export const SearchButton = styled.button`
  ${buttonStyles};
  right: 8px;

  &:disabled {
    cursor: not-allowed;

    svg {
      opacity: 0.5;
    }
  }
`;
