export const lineHeights = {
  body: '20px',
  bodyS: '18px',
  bodyXS: '16px',
  header1: '60px',
  header1Content: '37px',
  header2: '46px',
  header2Content: '28px',
  header3: '36px',
  header3Content: '24px',
  header4: '28px',
  header5: '24px',

  // 24px
  l: 1.7,

  // 20px
  m: 1.5,

  // 16px
  s: 1.25,

  // These are not in the style guide and should be replaced
  xs: 1, // 27.2px
} as const;

export type LineHeights = keyof typeof lineHeights;
