import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const ContactInformationStyled = styled.div`
  padding: 0 ${spacing.small};
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.large};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-start: ${spacing.mobileTop};
  }

  h2 {
    font-style: normal;
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    font-size: ${fontSizes.body};
    line-height: ${lineHeights.body};
    margin: 0 0 ${spacing.xsmall} 0;
  }

  p,
  span,
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: ${fontWeights.fontRegular};
    font-size: ${fontSizes.body};
    line-height: ${lineHeights.body};
    margin: 0;
  }

  a {
    display: block;

    &:hover {
      color: ${colors.solidBlackHover};
    }
  }

  .TextBlock {
    margin-block-end: ${spacing.regular};
  }
`;
