import { Children, useMemo } from 'react';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H2 } from '../H2/H2';
import { ProductElement } from '../product/product';
import { RelatedProductsSlider } from '../RelatedProductsSlider/RelatedProductsSlider';

import { RelatedProductsStyled } from './related-products.styled';
interface RelatedProductsProps {
  header?: string;
  isLoading?: boolean;
  numberOfItems?: number;
  children?: ProductElement | Iterable<ProductElement>;
}
export const RelatedProducts: React.FC<RelatedProductsProps> = ({
  header,
  children,
}) => {
  const onlyOneProduct = useMemo(() => {
    return Children.count(children) === 1;
  }, [children]);
  return (
    <RelatedProductsStyled>
      {header && (
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 4, start: 5 },
            ]}
          >
            <H2>{header}</H2>
          </GridChild>
        </Grid>
      )}
      <span className={`NoSlider ${onlyOneProduct ? 'Show' : 'DesktopOnly'}`}>
        <Grid
          columnGap={[{ columnGap: 20 }]}
          columns={[{ breakpoint: 'mobileMax', columns: 3 }]}
        >
          {children}
        </Grid>
      </span>
      <span className={`${onlyOneProduct ? 'Hide' : 'MobileOnly'}`}>
        <RelatedProductsSlider>{children}</RelatedProductsSlider>
      </span>
    </RelatedProductsStyled>
  );
};
