import styled from 'styled-components';

import { fontFamilies } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';

interface StyledH1Props {
  $center?: boolean;
  $bold?: boolean;
}

export const StyledH1 = styled.h1<StyledH1Props>`
  font-style: normal;
  font-size: ${fontSizes.header1};
  line-height: ${lineHeights.header1};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
  font-weight: ${({ $bold }) =>
    $bold ? fontWeights.fontMedium : fontWeights.fontRegular};
  font-family: ${({ $bold }) =>
    $bold ? fontFamilies.fontRegularBold : fontFamilies.fontRegular};
`;
