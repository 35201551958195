import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { TextBlockFragment } from '@hultafors/solidgear/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { GridChild } from '../grid-child/grid-child';

import * as Styled from './text-block.styled';
import { TextBlockStyled } from './text-block.styled';

export const TextBlock: React.FC<TextBlockFragment> = ({
  header,
  body,
  ctaLabel,
  ctaLink,
  smallerText,
}) => {
  const { settings } = useGlobal();

  return (
    <TextBlockStyled $smallerText={smallerText}>
      <Styled.Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          {header && (
            <Styled.HTag
              type="h2"
              verticalMargin="regular"
              noMarginTop
              styleType={[
                { styleType: 'header4' },
                { breakpoint: 'mobileMax', styleType: 'header4' },
              ]}
            >
              {header}
            </Styled.HTag>
          )}
          <Styled.Markdown options={{ forceBlock: true }}>
            {body ?? ''}
          </Styled.Markdown>
          {ctaLink && ctaLabel && (
            <ButtonLink href={createUrl(ctaLink, settings) || ''} gray>
              {ctaLabel}
            </ButtonLink>
          )}
        </GridChild>
      </Styled.Grid>
    </TextBlockStyled>
  );
};
