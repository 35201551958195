import dynamic from 'next/dynamic';

import { useGlobal } from '@hultafors/solidgear/hooks';
import { ProductSize } from '@hultafors/solidgear/types';

import { Paragraph } from '../paragraph/paragraph';

import { SizeButton, Sizes } from './size-selector.styled';

const SlideOut = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SlideOut),
);
const SlideOutHeader = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SlideOutHeader),
);

interface SizeSelectorProps {
  toggle(...props: any[]): void;
  isOpen?: boolean;
  sizes?: ProductSize[];
  toggleSelectSize(size: ProductSize): void;
  headerText?: string;
  selectedSize?: ProductSize;
  desktopMenu?: boolean;
}

export const SizeSelector: React.FC<SizeSelectorProps> = ({
  sizes = [],
  selectedSize,
  toggle,
  isOpen,
  toggleSelectSize,
  headerText,
}) => {
  const { global } = useGlobal();

  const isSizeSelected = (item: ProductSize): boolean => {
    return !!selectedSize && selectedSize.value === item.value;
  };

  function sizeMapper(item: ProductSize) {
    const onClick = () => {
      toggleSelectSize(item);
    };
    return (
      <SizeButton
        key={`Size-${item.value}`}
        onClick={onClick}
        $selected={isSizeSelected(item)}
      >
        <Paragraph>{item.value}</Paragraph>
      </SizeButton>
    );
  }

  const header = (
    <SlideOutHeader onClick={toggle} closeLabel={global?.close || ''}>
      {headerText}
    </SlideOutHeader>
  );

  return (
    <SlideOut isOpen={!!isOpen} toggle={toggle} header={header}>
      <Sizes>{sizes.map(sizeMapper)}</Sizes>
    </SlideOut>
  );
};
