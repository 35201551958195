import { useState } from 'react';

import { ProductFilter } from '@hultafors/shared/types';

import { SafetyClassValue } from '@hultafors/solidgear/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { SafetyClassIcon } from '../SafetyClassIcon/SafetyClassIcon';

import * as Styled from './safety-highlighted-filters.styled';

interface SafetyHighlightedFiltersProps {
  safetyFilters: ProductFilter;
  selectedFilters?: ProductFilter[];
  safetyClassesHeader: string;
  learnMore: string;
  filterChange(props: any): void;
  safetyFilterId: number;
}

export const SafetyHighlightedFilters: React.FC<
  SafetyHighlightedFiltersProps
> = ({
  safetyFilters,
  safetyClassesHeader,
  learnMore,
  filterChange,
  safetyFilterId,
}) => {
  const [isHovered, setIsHovered] = useState<any>(null);

  const handleTextButtonPress: React.MouseEventHandler = (event) => {
    event.preventDefault();
    document.getElementById('safety_class')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  function handleMouseEnterAndExit(value: any) {
    // check if hover is unavailable
    if (window.matchMedia('(hover: none)').matches) {
      return;
    } else {
      setIsHovered(value);
    }
  }
  if (!safetyFilters?.values?.length) {
    return null;
  }
  return (
    <Styled.SafetySection>
      <Grid>
        <GridChild>
          <Styled.SafetyHighlightedFilters>
            <Styled.SafetyTitleParagraph bold>
              {safetyClassesHeader}
            </Styled.SafetyTitleParagraph>
            {safetyFilters?.values &&
              safetyFilters.values.map((child, i) => {
                return (
                  <Styled.SafetyButton
                    $isFilterSelected={child.active}
                    key={`safety-button-${i}`}
                    onClick={() => {
                      filterChange({
                        AttrId: safetyFilterId,
                        ValueId: child.id,
                      });
                    }}
                    onMouseEnter={() => handleMouseEnterAndExit(child.id)}
                    onMouseLeave={() => handleMouseEnterAndExit(null)}
                  >
                    <SafetyClassIcon
                      safetyClass={child.description as SafetyClassValue}
                      filled={child.active}
                      hovered={isHovered !== null && child.id === isHovered}
                    />
                  </Styled.SafetyButton>
                );
              })}
            {learnMore && (
              <Styled.SafetyLearnMoreTextButton
                iconUrl="/assets/gfx/arrow_2_down.svg"
                onClick={handleTextButtonPress}
              >
                {learnMore}
              </Styled.SafetyLearnMoreTextButton>
            )}
          </Styled.SafetyHighlightedFilters>
        </GridChild>
      </Grid>
    </Styled.SafetySection>
  );
};
