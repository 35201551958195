import { StyledH1 } from './H1.styled';

interface H1Props {
  center?: boolean;
  bold?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const H1: React.FC<H1Props> = ({
  children,
  className,
  center,
  bold,
}) => {
  return (
    <StyledH1 className={className} $center={center} $bold={bold}>
      {children}
    </StyledH1>
  );
};
