import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/solidgear/helpers';

export const HeroCampaignContentStyled = styled.section`
  align-self: flex-end;

  h1,
  h2,
  h3 {
    text-shadow: 0 0 0.5rem rgb(0 0 0 / 80%);
  }

  p,
  h4,
  h5 {
    text-shadow: 0 0 0.25rem rgb(0 0 0 / 80%);
  }

  .BottomRegularSpacing {
    margin-block-end: ${spacing.regular};
  }

  .HeroContentWrapperTwo {
    @media screen and (min-width: ${breakpoints.desktop}) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .HeroVideoButtonWrapper {
    margin-block-start: ${spacing.regular};

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    button {
      inline-size: ${spacing.medium};
      block-size: ${spacing.medium};
      background: none;
      border: none;
      background-size: ${spacing.medium};
      cursor: pointer;
      background-image: url('/assets/gfx/video_play.svg');

      &:hover {
        background-image: url('/assets/gfx/video_play_hover.svg');
      }
    }
  }
`;
