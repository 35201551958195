import Markdown from 'markdown-to-jsx';

import { MaintenanceModeFragment } from '@hultafors/solidgear/types';

import { Alert } from '../Alert/Alert';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H2 } from '../H2/H2';
import { Loader } from '../loader/loader';

import { MaintenanceBlockStyled } from './maintenance-block.styled';

interface MaintenanceBlockContentProps {
  data?: MaintenanceModeFragment;
  error?: boolean;
  isLoading: boolean;
}

const MaintenanceBlockContent: React.FC<MaintenanceBlockContentProps> = ({
  data,
  error,
  isLoading,
}) => {
  if (isLoading) {
    return <Loader fullScreen />;
  }
  if (error) {
    return <Alert status="error" />;
  }
  if (!data || !data.enabled) {
    return null;
  }
  const title = data.title;
  const description = data.body ?? '';
  const renderDescription = () => (
    <Markdown className="BodyText" options={{ forceBlock: true }}>
      {description}
    </Markdown>
  );
  return (
    <MaintenanceBlockStyled>
      <div>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 8, start: 3 },
              { breakpoint: 'desktopMedium', columns: 6, start: 4 },
            ]}
          >
            <div className="wrapper">
              {title && <H2>{title}</H2>}
              {description && renderDescription()}
            </div>
          </GridChild>
        </Grid>
      </div>
    </MaintenanceBlockStyled>
  );
};

interface MaintenanceBlockProps {
  maintenanceData?: MaintenanceModeFragment;
  error?: boolean;
}

export const MaintenanceBlock: React.FC<MaintenanceBlockProps> = ({
  maintenanceData,
  error,
}) => {
  return (
    <MaintenanceBlockContent
      data={maintenanceData}
      error={error}
      isLoading={false}
    />
  );
};
