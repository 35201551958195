import { ButtonNature, StyledButton } from './button.styled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  stretch?: boolean;
  noWrap?: boolean;
  center?: boolean;
  lowerCase?: boolean;
  iconUrl?: string;
  nature?: ButtonNature;
}

export const Button: React.FC<ButtonProps> = ({
  stretch,
  children,
  nature = 'default',
  className,
  noWrap,
  iconUrl,
  center,
  lowerCase,
  ...rest
}) => {
  return (
    <StyledButton
      className={className}
      $stretch={stretch}
      $nature={nature}
      $noWrap={noWrap}
      $iconUrl={iconUrl}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};
