import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

interface SmallPlugStyledProps {
  $backgroundColor?: string;
  $color?: string;
  $hasTextShadow?: boolean;
  $small?: boolean;
}

export const SmallPlugStyled = styled.div<SmallPlugStyledProps>`
  position: relative;
  height: 356px;
  width: 100%;
  z-index: 1;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : colors.white};

  button {
    block-size: ${spacing.medium};
    padding: 0 ${spacing.regular};
  }

  .Grid {
    inline-size: 100%;
    margin: auto;
  }

  .Content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    inline-size: 100%;
    block-size: 100%;
    margin: auto;
  }

  .Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 ${spacing.regular};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: 0;
      ${({ $small }) =>
        $small
        && css`
          padding: 0 ${spacing.xsmall};
        `}
    }

    a {
      overflow: hidden;
      white-space: nowrap;
      max-inline-size: 100%;
      color: ${colors.white};
    }
  }

  ${({ $hasTextShadow }) =>
    $hasTextShadow
    && css`
      h2 {
        text-shadow: 0 4px 30px rgb(0 0 0 / 25%);
      }
    `}
  h2 {
    color: ${({ $color }) => $color ?? colors.solidBlack};
    margin-block: 0 ${spacing.regular};
    font-family: ${fontFamilies.fontHero};
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: ${({ $small }) =>
      $small ? fontSizes.header4 : fontSizes.header3};
    line-height: ${({ $small }) =>
      $small ? lineHeights.header4 : lineHeights.header3};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${({ $small }) =>
        $small ? fontSizes.header4 : fontSizes.header2};
      line-height: ${({ $small }) =>
        $small ? lineHeights.header4 : lineHeights.header2};
    }
  }
`;
