import styled from 'styled-components';

import { spacing } from '@hultafors/solidgear/helpers';

export const SafetyClassDivisionStyled = styled.div`
  margin-top: ${spacing.small};
  display: flex;
  flex-direction: row;
  .Icon {
    margin: ${spacing.regular} 0;
  }
  .AnchorButton {
    margin-top: 16px;
    padding-left: ${spacing.xsmall};
  }
  .AnchorButton:hover {
    &.AnchorButton::after {
      transition: all 0.3s ease;
      transform: translateY(3px);
    }
  }
`;
