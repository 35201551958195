import { RadioButtonStyled } from './RadioButton.styled';
interface RadioButtonProps {
  id?: string;
  changed?: (...args: any[]) => any;
  value?: string | number;
  name?: string;
  color?: string;
  isSelected?: boolean;
  label?: string;
}
export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  return (
    <RadioButtonStyled $color={props.color}>
      <input
        id={props.id}
        onChange={props.changed}
        value={props.value}
        name={props.name}
        type="radio"
        checked={props.isSelected}
        color={props.color}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </RadioButtonStyled>
  );
};
