import { DialogContent, DialogHeader, DialogWrapper } from './Dialog.styled';
interface DialogProps {
  closeButton?: React.ReactNode;
  isFullscreen?: boolean;
  height?: string;
  width?: string;
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  children?: React.ReactNode;
}
export const Dialog: React.FC<DialogProps> = ({
  children,
  closeButton,
  isFullscreen = true,
  height = '100%',
  width = '100%',
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
}) => (
  <DialogWrapper
    $top={top}
    $right={right}
    $bottom={bottom}
    $left={left}
    $height={height}
    $width={width}
  >
    <DialogHeader $isFullscreen={isFullscreen}>{closeButton}</DialogHeader>
    <DialogContent>{children}</DialogContent>
  </DialogWrapper>
);
