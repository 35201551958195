import styled, { css } from 'styled-components';

import { colors, spacing } from '@hultafors/solidgear/helpers';

export const Sizes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spacing.medium};
  padding: 0 calc(20px - 2%);
`;

export const SizeButton = styled.button<{ $selected?: boolean }>`
  &:focus {
    outline: 0;
  }

  flex: 0 1 23%;
  margin: 1%;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray4};
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 50px;
  line-height: 32px;
  border-radius: 1px;
  align-items: center;

  &:hover {
    border-color: ${colors.black};
  }

  ${({ $selected }) =>
    $selected
    && css`
      background-color: ${colors.black};
      border-color: ${colors.black};

      p {
        color: ${colors.white};
      }
    `}
`;
