import Image from 'next/image';
import { useKey } from 'react-use';

import { ParttrapImage } from '@hultafors/shared/types';

import { colors, createProductImageSrc } from '@hultafors/solidgear/helpers';

import { Dialog } from '../Dialog/Dialog';
import { Loader } from '../loader/loader';

import {
  CloseButton,
  DialogImage,
  ProductImageDialogStyled,
  StyledLoader,
} from './ProductImageDialog.styled';

interface ProductImageDialogProps {
  images?: ParttrapImage[];
  onClick?(): void;
  closeLabel?: string;
  productName?: string;
}
export const ProductImageDialog: React.FC<ProductImageDialogProps> = ({
  images = [],
  onClick,
  closeLabel,
  productName,
}) => {
  useKey('Escape', onClick);

  return (
    <ProductImageDialogStyled>
      <Dialog
        closeButton={(
          <CloseButton aria-label={closeLabel || 'close'} onClick={onClick}>
            <Image
              src="/assets/gfx/close_white_24.svg"
              width={24}
              height={24}
              alt={closeLabel || 'close'}
            />
          </CloseButton>
        )}
        height="100%"
        width="100%"
      >
        <StyledLoader as={Loader} fullScreen />
        {images.map((image, index) => (
          <DialogImage key={`image${index}`}>
            <Image
              src={createProductImageSrc(image.url ?? '')}
              alt={image.caption ?? `${productName} 'image`}
              fill
              sizes="100vw"
              style={{ backgroundColor: colors.gray5 }}
            />
          </DialogImage>
        ))}
      </Dialog>
    </ProductImageDialogStyled>
  );
};
