import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const MaintenanceBlockStyled = styled.div`
  &.TopBorder {
    border-block-start: 1px solid ${colors.gray4};
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${spacing.mobileTop} ${spacing.small} ${spacing.mobileTop}
      ${spacing.small};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    text-align: center;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-block: ${spacing.large};
    }
  }

  h1,
  h2 {
    font-style: normal;
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
    font-size: ${fontSizes.header2Content};
    line-height: ${lineHeights.header2Content};
    text-align: center;
    margin: 0;
    margin-block-end: ${spacing.regular};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${fontSizes.header1Content};
      line-height: ${lineHeights.header1Content};
    }
  }
`;
