import { CSSProperties } from 'styled-components';

import { Spacing } from '@hultafors/solidgear/helpers';

import { HTagStyleTypeProps, StyledHTag } from './HTag.styled';

type HTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
interface HTagProps {
  center?: boolean;
  className?: string;
  color?: CSSProperties['color'];
  type: HTagType;
  verticalMargin?: Spacing;
  styleType?: HTagStyleTypeProps;
  children?: React.ReactNode;
  noMarginTop?: boolean;
  style?: React.CSSProperties;
}
export const HTag: React.FC<HTagProps> = ({
  type = 'h1',
  className,
  style,
  children,
  center,
  styleType,
  verticalMargin,
  color,
  noMarginTop,
}) => {
  return (
    <StyledHTag
      as={type}
      className={className}
      style={style}
      $center={center}
      $styleType={styleType}
      $verticalMargin={verticalMargin}
      $color={color}
      $noMarginTop={noMarginTop}
    >
      {children}
    </StyledHTag>
  );
};
