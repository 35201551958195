import { useId } from 'react';

import dynamic from 'next/dynamic';

import { useShoeGuide } from '@hultafors/solidgear/hooks';
import {
  CampaignPageFeedFragment,
  ContentPageFeedFragment,
  isBulletList,
  isCampaignTextBlock,
  isContentBulletList,
  isContentQuote,
  isDoubleTextBlock,
  isDualImage,
  isFactBox,
  isImageBlock,
  isImageSlider,
  isProductPlugList,
  isSafetyStandardTableBlock,
  isShoeGuideCta,
  isTextBlock,
  isVideoBlock,
} from '@hultafors/solidgear/types';

import { Button } from '../button/button';

import * as Styled from './dynamic-content.styled';

const BulletListBlock = dynamic(() =>
  import('../BulletListBlock/BulletListBlock').then(
    (mod) => mod.BulletListBlock,
  ),
);
const ContentBulletList = dynamic(() =>
  import('../content-bullet-list/content-bullet-list').then(
    (mod) => mod.ContentBulletList,
  ),
);
const ContentQuoteBlock = dynamic(() =>
  import('../ContentQuoteBlock/ContentQuoteBlock').then(
    (mod) => mod.ContentQuoteBlock,
  ),
);
const TwoColumnText = dynamic(() =>
  import('../TwoColumnText/TwoColumnText').then((mod) => mod.TwoColumnText),
);
const DualImage = dynamic(() =>
  import('../DualImage/DualImage').then((mod) => mod.DualImage),
);
const FactBoxBlock = dynamic(() =>
  import('../FactBoxBlock/FactBoxBlock').then((mod) => mod.FactBoxBlock),
);
const ImageBlock = dynamic(() =>
  import('../image-block/image-block').then((mod) => mod.ImageBlock),
);
const ImageSliderBlock = dynamic(() =>
  import('../image-slider-block/image-slider-block').then(
    (mod) => mod.ImageSliderBlock,
  ),
);
const ProductPlugsBlock = dynamic(() =>
  import('../ProductPlugsBlock/ProductPlugsBlock').then(
    (mod) => mod.ProductPlugsBlock,
  ),
);
const SafetyStandardTableBlock = dynamic(() =>
  import('../safety-standard-table-block/safety-standard-table-block').then(
    (mod) => mod.SafetyStandardTableBlock,
  ),
);
const TextBlock = dynamic(() =>
  import('../text-block/text-block').then((mod) => mod.TextBlock),
);
const VideoBlock = dynamic(() =>
  import('../VideoBlock/VideoBlock').then((mod) => mod.VideoBlock),
);

interface DynamicContentProps {
  content?: (ContentPageFeedFragment | CampaignPageFeedFragment)[];
}

export const DynamicContent: React.FC<DynamicContentProps> = ({
  content = [],
}) => {
  const uid = useId();
  const context = useShoeGuide();

  if (!content?.length) {
    return null;
  }

  const feedMapper = (item: any, index: number) => {
    const key = `ContentArea-${uid}-${item.id}`;
    if (isTextBlock(item)) {
      return <TextBlock {...item} key={key} />;
    }
    if (isBulletList(item) || isCampaignTextBlock(item)) {
      return <BulletListBlock data={item} key={key} />;
    }
    if (isContentBulletList(item)) {
      return <ContentBulletList key={key} {...item} />;
    }
    if (isImageBlock(item)) {
      return <ImageBlock {...item} key={key} />;
    }
    if (isImageSlider(item)) {
      return <ImageSliderBlock {...item} key={key} />;
    }
    if (isDualImage(item)) {
      return <DualImage {...item} key={key} />;
    }
    if (isShoeGuideCta(item)) {
      return (
        <Styled.DynamicWrapper key={key}>
          <Button
            className="Black"
            style={{ margin: '0 auto' }}
            onClick={context.toggleGuide}
          >
            {item.buttonText}
          </Button>
        </Styled.DynamicWrapper>
      );
    }
    if (isFactBox(item)) {
      return <FactBoxBlock {...item} key={key} />;
    }
    if (isContentQuote(item)) {
      return <ContentQuoteBlock {...item} key={key} />;
    }
    if (isDoubleTextBlock(item)) {
      return <TwoColumnText {...item} key={key} />;
    }
    if (isProductPlugList(item)) {
      return <ProductPlugsBlock {...item} key={key} />;
    }
    if (isVideoBlock(item)) {
      return <VideoBlock {...item} key={key} />;
    }
    if (isSafetyStandardTableBlock(item)) {
      return <SafetyStandardTableBlock {...item} key={key} />;
    }
    return null;
  };
  return <>{content.map(feedMapper)}</>;
};
