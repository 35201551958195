import { BoxStyled } from './box.styled';

interface BoxProps {
  children?: React.ReactNode;
  className?: string;
}

export const Box: React.FC<BoxProps> = ({ children, className }) => (
  <BoxStyled className={className}>{children}</BoxStyled>
);
