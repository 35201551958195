import { SafetyClassValue } from '@hultafors/solidgear/types';

import O1Svg from '../../svg/safety_class_O1.svg';
import O1FilledSvg from '../../svg/safety_class_O1_filled.svg';
import O2Svg from '../../svg/safety_class_O2.svg';
import O2FilledSvg from '../../svg/safety_class_O2_filled.svg';
import O6Svg from '../../svg/safety_class_O6.svg';
import O6FilledSvg from '../../svg/safety_class_O6_filled.svg';
import S1Svg from '../../svg/safety_class_S1.svg';
import S1FilledSvg from '../../svg/safety_class_S1_filled.svg';
import S1PSvg from '../../svg/safety_class_S1P.svg';
import S1PFilledSvg from '../../svg/safety_class_S1P_filled.svg';
import S1PLSvg from '../../svg/safety_class_S1PL.svg';
import S1PLFilledSvg from '../../svg/safety_class_S1PL_filled.svg';
import S1PSSvg from '../../svg/safety_class_S1PS.svg';
import S1PSFilledSvg from '../../svg/safety_class_S1PS_filled.svg';
import S2PSvg from '../../svg/safety_class_S2P.svg';
import S2PFilledSvg from '../../svg/safety_class_S2P_filled.svg';
import S3Svg from '../../svg/safety_class_S3.svg';
import S3FilledSvg from '../../svg/safety_class_S3_filled.svg';
import S3LSvg from '../../svg/safety_class_S3L.svg';
import S3LFilledSvg from '../../svg/safety_class_S3L_filled.svg';
import S3SSvg from '../../svg/safety_class_S3S.svg';
import S3SFilledSvg from '../../svg/safety_class_S3S_filled.svg';
import S7LSvg from '../../svg/safety_class_S7L.svg';
import S7LFilledSvg from '../../svg/safety_class_S7L_filled.svg';
import S7SSvg from '../../svg/safety_class_S7S.svg';
import S7SFilledSvg from '../../svg/safety_class_S7S_filled.svg';

import { SafetyClassIconsStyled } from './SafetyClassIcon.styled';
interface SafetyClassIconProps {
  safetyClass: SafetyClassValue;
  filled?: boolean;
  hovered?: boolean;
}

export const SafetyClassIcon: React.FC<SafetyClassIconProps> = ({
  safetyClass,
  filled,
  hovered,
}) => {
  const resolveSafetyClassIcon = (safetyClass: SafetyClassValue) => {
    switch (safetyClass) {
      case 'O1':
        return <O1Svg width={48} height={48} />;
      case 'O2':
        return <O2Svg width={48} height={48} />;
      case 'O6':
        return <O6Svg width={48} height={48} />;
      case 'S1':
        return <S1Svg width={48} height={48} />;
      case 'S1P':
        return <S1PSvg width={48} height={48} />;
      case 'S1PL':
        return <S1PLSvg width={48} height={48} />;
      case 'S1PS':
        return <S1PSSvg width={48} height={48} />;
      case 'S2P':
        return <S2PSvg width={48} height={48} />;
      case 'S3':
        return <S3Svg width={48} height={48} />;
      case 'S3L':
        return <S3LSvg width={48} height={48} />;
      case 'S3S':
        return <S3SSvg width={48} height={48} />;
      case 'S7L':
        return <S7LSvg width={48} height={48} />;
      case 'S7S':
        return <S7SSvg width={48} height={48} />;
      default:
        return null;
    }
  };

  const resolveFilledSafetyClassIcon = (safetyClass: SafetyClassValue) => {
    switch (safetyClass) {
      case 'O1':
        return <O1FilledSvg width={48} height={48} />;
      case 'O2':
        return <O2FilledSvg width={48} height={48} />;
      case 'O6':
        return <O6FilledSvg width={48} height={48} />;
      case 'S1':
        return <S1FilledSvg width={48} height={48} />;
      case 'S1P':
        return <S1PFilledSvg width={48} height={48} />;
      case 'S1PL':
        return <S1PLFilledSvg width={48} height={48} />;
      case 'S1PS':
        return <S1PSFilledSvg width={48} height={48} />;
      case 'S2P':
        return <S2PFilledSvg width={48} height={48} />;
      case 'S3':
        return <S3FilledSvg width={48} height={48} />;
      case 'S3L':
        return <S3LFilledSvg width={48} height={48} />;
      case 'S3S':
        return <S3SFilledSvg width={48} height={48} />;
      case 'S7L':
        return <S7LFilledSvg width={48} height={48} />;
      case 'S7S':
        return <S7SFilledSvg width={48} height={48} />;
      default:
        return null;
    }
  };

  return (
    <SafetyClassIconsStyled>
      {filled || hovered
        ? resolveFilledSafetyClassIcon(safetyClass)
        : resolveSafetyClassIcon(safetyClass)}
    </SafetyClassIconsStyled>
  );
};
