import styled from 'styled-components';

import { colors, spacing } from '@hultafors/solidgear/helpers';

export const SelectedFiltersStyled = styled.div`
  margin-left: ${spacing.regular};

  .SelectedFilters {
    display: flex;
    overflow-x: scroll;
  }

  .TextButton {
    white-space: nowrap;
    padding: ${spacing.xsmall} ${spacing.regular} ${spacing.regular} 0;

    &:hover {
      color: ${colors.solidBlackHover};
    }
  }
`;
