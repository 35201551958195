import Link from 'next/link';

import { RouterLinkStyled } from './RouterLink.styled';
export { RouterLinkStyled } from './RouterLink.styled';

interface RouterLinkProps {
  dataValue?: any;
  href: string;
  children?: React.ReactNode;
  id?: string;
  onClick?: any;
  linkType?: string;
  className?: string;
}

export const RouterLink: React.FC<RouterLinkProps> = ({
  dataValue,
  href,
  children,
  id,
  onClick,
  linkType,
  className,
}) => {
  return (
    <RouterLinkStyled
      $linkType={linkType}
      data-value={dataValue}
      className={className}
    >
      {href.startsWith('http')
        ? (
          <a
            href={href}
            className="link"
            target="_blank"
            rel="noreferrer noopener"
          >
            {children}
          </a>
          )
        : (
          <Link href={href} passHref id={id} onClick={onClick} className="link">
            {children}
          </Link>
          )}
    </RouterLinkStyled>
  );
};
