import { IconButtonStyled } from './icon-button.styled';
import NextIcon from './icon-next.svg';

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  IconNext?: boolean;
  fullWidth?: boolean;
  lowerCase?: boolean;
  center?: boolean;
  iconUrl?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  type = 'submit',
  IconNext,
  fullWidth,
  lowerCase,
  center,
  iconUrl,
  ...rest
}) => (
  <IconButtonStyled
    type={type}
    $fullWidth={fullWidth}
    $lowerCase={lowerCase}
    $center={center}
    $iconUrl={iconUrl}
    {...rest}
  >
    {children}
    {IconNext && (
      <NextIcon width={16} height={16} aria-hidden={true} focusable={false} />
    )}
  </IconButtonStyled>
);
