import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

type GridChildColumnSpanValue = number | string;

interface GridChildResponsiveColumn {
  breakpoint?: Breakpoint;
  display?: CSSProperties['display'];
  start?: GridChildColumnSpanValue;
  columns?: GridChildColumnSpanValue;
}
export type GridChildColumnSpanProp =
  | GridChildColumnSpanValue
  | GridChildResponsiveColumn[];

interface StyledGridChildProps {
  $align?: CSSProperties['justifySelf'];
  $alignSelf?: CSSProperties['alignSelf'];
  $padding?: CSSProperties['padding'];
  $rowStart?: CSSProperties['gridRowStart'];
  $rowSpan?: CSSProperties['gridRowEnd'];
  $columnSpan?: GridChildColumnSpanProp;
}

const getColumnSpan = ({ $columnSpan }: StyledGridChildProps) => {
  if (Array.isArray($columnSpan)) {
    return $columnSpan.map(({ start, columns, breakpoint, display }) => {
      const end = columns ? `span ${columns}` : '-1';
      if (breakpoint) {
        return css`
          @media all and (min-width: ${breakpoints[breakpoint]}) {
            display: ${display || 'initial'};
            grid-column: ${start ? `${start} /` : ''} ${end};
          }
        `;
      }

      return css`
        display: ${display || 'initial'};
        grid-column: ${start ? `${start} /` : ''} ${end};
      `;
    });
  }
  return css`
    grid-column: ${$columnSpan || '1 / -1'};
  `;
};

export const StyledGridChild = styled.div<StyledGridChildProps>`
  position: relative;
  place-self: ${({ $alignSelf = 'inherit' }) => $alignSelf}
    ${({ $align = 'inherit' }) => $align};
  padding: ${({ $padding = 'inherit' }) => $padding};
  grid-row: ${({ $rowStart = 'inherit' }) => $rowStart} /
    ${({ $rowSpan }) => ($rowSpan ? `span ${$rowSpan}` : 'inherit')};

  ${getColumnSpan};
`;
