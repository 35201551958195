import styled from 'styled-components';

import { colors, spacing } from '@hultafors/solidgear/helpers';

import { Button } from '../button/button';

export const ProductImageDialogStyled = styled.aside``;

export const StyledLoader = styled.div`
  position: absolute;
  width: 100%;
`;

export const DialogImage = styled.div`
  position: relative;
  display: block;
  width: calc(100vw - 48px);
  height: calc(100vw - 48px);
  margin-top: ${spacing.regular};
  margin-bottom: ${spacing.regular};
`;

export const CloseButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.solidBlack};

  :hover {
    background-color: ${colors.solidBlackHover};
  }
`;
