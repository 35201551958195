import { ContentPlugFragment } from '@hultafors/solidgear/types';

import { ContentPlug } from '../content-plug/content-plug';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { DualContentPlugStyled } from './dual-content-plug.styled';

interface DualContentPlugProps {
  contentPlugs?: ContentPlugFragment[];
}

export const DualContentPlug: React.FC<DualContentPlugProps> = ({
  contentPlugs = [],
}) => {
  return (
    <DualContentPlugStyled>
      <Grid columns={2} columnGap={0} maxWidth="none">
        {contentPlugs.slice(0, 2).map((item, index) => {
          return (
            <GridChild
              key={`contentPlug${index}`}
              columnSpan={[
                { columns: 2 },
                { breakpoint: 'mobileMax', columns: 1, start: index + 1 },
              ]}
            >
              <ContentPlug
                title={item.title || ''}
                url={item.url || ''}
                image={item.image}
              />
            </GridChild>
          );
        })}
      </Grid>
    </DualContentPlugStyled>
  );
};
