import { BadgeStyled } from './Badge.styled';
interface BadgeProps {
  small?: boolean;
  tiny?: boolean;
  red?: boolean;
  round?: boolean;
  inline?: boolean;
  styleName?: string;
  new?: boolean;
  premium?: boolean;
  children?: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  styleName,
  round,
  inline,
  red,
  small,
  tiny,
  premium,
  ...rest
}) => {
  return (
    <BadgeStyled
      className={styleName}
      $inline={inline}
      $round={round}
      $red={red}
      $small={small}
      $tiny={tiny}
      $new={rest.new}
      $premium={premium}
    >
      {children}
    </BadgeStyled>
  );
};
