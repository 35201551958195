import Image from 'next/image';

import {
  ContentPageLinkFragment,
  ImageFragment,
} from '@hultafors/solidgear/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { ContentPlugStyled } from './content-plug.styled';

interface ContentPlugProps {
  title?: string | null;
  url?: string | null;
  image?: ImageFragment | null;
  className?: string;
  pageLink?: ContentPageLinkFragment | null;
  sizes?: string | null;
}

export const ContentPlug: React.FC<ContentPlugProps> = ({
  title = '',
  url = '',
  image,
  className,
  sizes = '100vw',
}) => {
  return (
    <ContentPlugStyled href={url ?? ''} className={className}>
      <Image
        src={image?.responsiveImage?.src ?? ''}
        alt={image?.alt ?? ''}
        fill
        className="Image"
        sizes={sizes ?? 'auto'}
        placeholder="blur"
        blurDataURL={image?.responsiveImage?.base64 ?? undefined}
      />
      <Grid className="Grid">
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 8, start: 3 },
          ]}
        >
          <div className="TextBoxWrapper">
            {title && <span className="TextBox">{title}</span>}
          </div>
        </GridChild>
      </Grid>
    </ContentPlugStyled>
  );
};
