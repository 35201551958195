import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageFragment } from '@hultafors/solidgear/types';

import { IconButton } from '../icon-button/icon-button';

import {
  BackDrop,
  DialogContent,
  ImageWrapper,
  StyledDialog,
  StyledIFrameContainer,
} from './SubscriptionDialog.styled';
interface SubscriptionDialogProps {
  formUrl?: string;
  image?: ImageFragment | null;
  onHandleClose?(...args: any[]): void;
  closeLabel?: string;
}

export const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  formUrl,
  image,
  onHandleClose,
  closeLabel,
}) => {
  const sizes = `width: 0, (min-width: ${breakpoints.tablet}) 33vw, (min-width: ${breakpoints.desktopMedium}) 400px`;
  return (
    <>
      <BackDrop />
      <StyledDialog
        isFullscreen={false}
        closeButton={(
          <IconButton onClick={onHandleClose} className="IconButton">
            <Image
              src="/assets/gfx/close.svg"
              alt={closeLabel || ''}
              width={24}
              height={24}
            />
          </IconButton>
        )}
      >
        <DialogContent>
          {image?.responsiveImage?.src && (
            <ImageWrapper>
              <Image
                src={image.responsiveImage.src}
                alt={image.alt || ''}
                sizes={sizes}
                fill
              />
            </ImageWrapper>
          )}
          <StyledIFrameContainer title="dialog-form" src={formUrl} />
        </DialogContent>
      </StyledDialog>
    </>
  );
};
