import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

interface LargePlugStyledProps {
  $image?: boolean;
  $small?: boolean;
}

export const LargePlugStyled = styled.div<LargePlugStyledProps>`
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  color: ${({ $image }) => ($image ? colors.white : colors.solidBlack)};
  background-color: ${colors.solidBlack};
  border-bottom: ${({ $image }) =>
    $image ? 'none' : '1px solid ' + colors.gray4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 4 / 3;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 12 / 5;
  }

  .Grid {
    inline-size: 100%;
  }

  .Content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    inline-size: 100%;
    block-size: 100%;
    padding-block-end: ${spacing.medium};
  }

  .Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 ${spacing.regular};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: 0;
      ${({ $small }) =>
        $small
        && css`
          padding: 0 ${spacing.xsmall};
        `}
    }

    a,
    .link {
      overflow: hidden;
      white-space: nowrap;
      max-inline-size: 100%;
    }
  }

  h2 {
    color: ${colors.white};
    font-family: ${fontFamilies.fontHero};
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 4px 30px rgb(0 0 0 / 25%);
    margin-block-end: ${spacing.regular};
    font-size: ${({ $small }) =>
      $small ? fontSizes.header4 : fontSizes.header3};
    line-height: ${({ $small }) =>
      $small ? lineHeights.header4 : lineHeights.header3};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${({ $small }) =>
        $small ? fontSizes.header4 : fontSizes.header2};
      line-height: ${({ $small }) =>
        $small ? lineHeights.header4 : lineHeights.header2};
    }
  }
`;
