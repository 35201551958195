export const fontSizes = {
  body: '14px',
  bodyS: '12px',
  bodyXS: '10px',
  // These are not in the style guide and should be replaced
  h1: '3.125rem',

  h1mobile: '1.875rem',

  h2: '1.875rem',

  h2mobile: '1.5rem',

  h3: '1.5rem',

  h4: '1.25rem',

  // 20px
  h5: '1.125rem',

  header1: '60px',

  header1Content: '30px',

  header2: '46px',

  header2Content: '22px',

  header3: '36px',

  header3Content: '18px',
  header4: '28px', // 18px
  small: '0.875rem',
  supersmall: '0.625rem',
  // 14px
  xsmall: '0.75rem',
} as const;

export type FontSizes = keyof typeof fontSizes;
