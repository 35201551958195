import styled from 'styled-components';

import { fontSizes } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';

interface StyledH5Props {
  $center?: boolean;
}

export const H5Styled = styled.h5<StyledH5Props>`
  text-align: ${({ $center }) => ($center ? 'center' : '')};
  font-size: ${fontSizes.h5};
  line-height: ${lineHeights.header5};
  font-weight: ${fontWeights.fontRegular};
  margin: 0;
`;
