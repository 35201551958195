import { H5Styled } from './H5.styled';
interface H5Props {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
}
export const H5: React.FC<H5Props> = ({ children, className, center }) => {
  return (
    <H5Styled className={className} $center={center}>
      {children}
    </H5Styled>
  );
};
