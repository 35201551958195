import { SolidgearProduct } from '@hultafors/solidgear/types';

import { Product } from '../product/product';

interface ProductsProps {
  products: SolidgearProduct[];
  rrpLabel: string;
  rrpIncludingVatLabel: string;
  toggleSearchBox?: (type: string) => void;
}

export const Products: React.FC<ProductsProps> = ({
  toggleSearchBox,
  products,
  rrpLabel,
  rrpIncludingVatLabel,
}) => {
  return (
    products && (
      <>
        {products.map((product, i) => {
          return (
            <Product
              key={`p-${i}`}
              product={product}
              className="Product"
              rrpLabel={rrpLabel}
              rrpIncludingVatLabel={rrpIncludingVatLabel}
              toggleSearchBox={toggleSearchBox}
            />
          );
        })}
      </>
    )
  );
};
