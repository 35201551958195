import { useShoeGuide } from '@hultafors/solidgear/hooks';

import { Button } from '../button/button';

import { GuideButtonStyled } from './guide-button.styled';

interface GuideButtonProps {
  pageContent?: any;
}
export const GuideButton: React.FC<GuideButtonProps> = ({ pageContent }) => {
  const guideContext = useShoeGuide();
  const hasShoeGuideButton = pageContent.shoe_guide_button;
  if (!hasShoeGuideButton) {
    return null;
  }
  return (
    <GuideButtonStyled className="GuideButtonContainer">
      <Button onClick={guideContext.toggleGuide} className="Black">
        {pageContent.shoe_guide_button_label}
      </Button>
    </GuideButtonStyled>
  );
};
