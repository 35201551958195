import { createContext, FC, useEffect, useState } from 'react';

import useSWR from 'swr';

import { ShoeGuideQuery } from '@hultafors/solidgear/types';

export interface GuideContextValue {
  isGuideOpen: boolean;
  shoeGuide: ShoeGuideQuery['shoeGuide'];
  toggleGuide(): void;
}

const defaultGuideContextValue: GuideContextValue = {
  isGuideOpen: false,
  shoeGuide: null,
  toggleGuide: () => {
    return;
  },
};

export const GuideContext = createContext<GuideContextValue>(
  defaultGuideContextValue
);

export interface GuideProviderProps {
  children?: React.ReactNode;
  value?: GuideContextValue;
}

export const GuideProvider: FC<GuideProviderProps> = ({ children }) => {
  const [shoeGuide, setShoeGuide] = useState<ShoeGuideQuery['shoeGuide']>();
  const [isGuideOpen, setIsGuideOpen] = useState<boolean>(false);
  const toggleGuide = () => {
    setIsGuideOpen(!isGuideOpen);
  };

  const { data, error } = useSWR<ShoeGuideQuery['shoeGuide']>(
    '/api/getShoeGuide',
    {
      revalidateOnMount: true,
    }
  );

  useEffect(() => {
    if (data) {
      setShoeGuide(data);
    }
  }, [data, error]);

  return (
    <GuideContext.Provider
      value={{
        isGuideOpen,
        shoeGuide,
        toggleGuide,
      }}
    >
      {children}
    </GuideContext.Provider>
  );
};
