import Markdown from 'markdown-to-jsx';

import { ImageFragment } from '@hultafors/solidgear/types';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H1 } from '../H1/H1';
import { HeroImage } from '../HeroImage/HeroImage';

import { ContentHeroStyled } from './content-hero.styled';
export interface ContentHeroProps {
  title: string;
  description?: string;
  imageDesktop?: ImageFragment | null;
  imageTablet?: ImageFragment | null;
  imageMobile?: ImageFragment | null;
  splitHero?: boolean;
  type?: 'contentView' | 'contactView' | 'NotFoundView' | 'ContentListView';
}
export const ContentHero: React.FC<ContentHeroProps> = ({
  imageDesktop,
  imageTablet,
  imageMobile,
  type = 'contentView',
  description = '',
  title,
  splitHero,
}) => {
  if (!title) {
    return null;
  }
  return (
    <ContentHeroStyled $splitHero={splitHero}>
      <div className="hero-content">
        <Grid className="noMaxWidth">
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'tablet', columns: 8, start: 3 },
              { breakpoint: 'desktop', columns: 6, start: 4 },
            ]}
          >
            <div className={`hero-text-wrapper ${type}`}>
              <H1 bold className={`hero-title ${type}`}>
                {title}
              </H1>
              {description && (
                <Markdown
                  className="hero-description bold"
                  options={{ forceBlock: true }}
                >
                  {description}
                </Markdown>
              )}
            </div>
          </GridChild>
        </Grid>

        {(imageMobile || imageTablet || imageDesktop) && (
          <div className="HeroImage">
            <HeroImage
              mobile={splitHero ? imageTablet : imageMobile}
              tablet={splitHero ? imageTablet : imageTablet}
              desktop={splitHero ? imageTablet : imageDesktop}
              priority={true}
            />
          </div>
        )}
      </div>
    </ContentHeroStyled>
  );
};
