import styled from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';

export const ShoeGuideStyled = styled.div`
  text-align: center;
  position: relative;

  .GuideContainer {
    padding: ${spacing.small};
    block-size: 100%;
  }

  .LoaderContainer {
    block-size: 100%;
    display: flex;
    justify-content: center;
  }

  .SlideInBody {
    box-sizing: border-box;
    overflow: scroll;
  }

  .BackButtonContainer {
    text-align: start;

    button {
      padding: 0;

      :hover {
        color: ${colors.solidBlackHover};
      }
    }
  }

  .ShoeGuideMultiChoiceContainer {
    margin-block-end: ${spacing.large};
  }

  .StartButton {
    padding: ${spacing.small} ${spacing.medium};
    font-size: ${fontSizes.body};
    line-height: ${fontSizes.body};
    inline-size: auto;
  }

  .NextButtonContainer {
    position: absolute;
    padding: ${spacing.small};
    inset-block-end: 0;
    inline-size: 100%;
    padding-block-start: ${spacing.medium};
  }

  .NextButton {
    inline-size: 100%;
  }

  h1 {
    margin: ${spacing.mobileTop} 0 ${spacing.medium} 0;
    text-align: center;
    font-size: ${fontSizes.header1Content};
    line-height: ${lineHeights.header1Content};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
  }

  h2 {
    text-align: center;
    margin: ${spacing.medium} 0 ${spacing.regular} 0;
    font-size: ${fontSizes.header2Content};
    line-height: ${lineHeights.header2Content};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
  }

  h3 {
    text-align: center;
    margin: ${spacing.regular} 0 ${spacing.small} 0;
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};
  }

  .ContentWrapper {
    padding: 20px 20px 104px;
  }

  .Result .ContentWrapper {
    padding: 20px;
  }

  /* From GuideStep */

  input {
    position: absolute;
    opacity: 0;
  }

  .GuideStepHeadingContainer {
    padding: 0 ${spacing.small};
    margin: 0 -${spacing.small};
  }

  .SubAnswersContainer {
    border-block-start: 1px solid ${colors.gray4};
    margin: 0 -${spacing.small};
    padding: 0 ${spacing.small};
    margin-block-end: ${spacing.regular};
  }

  .AnswersContainerMultiChoice {
    padding-block-start: ${spacing.small};
  }

  .AnswersContainer {
    border-block-start: 1px solid ${colors.gray4};
    margin: 0 -${spacing.small};
    padding: ${spacing.regular} ${spacing.small};
  }

  .CheckboxContainer {
    padding: ${spacing.regular} ${spacing.small};
    border-block-start: 1px solid ${colors.gray4};
    margin: 0 -${spacing.small};

    &:last-of-type {
      border-block-end: 1px solid ${colors.gray4};
    }
  }

  .CheckboxLabel {
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: ' ';
      position: absolute;
      inset-inline-start: 0;

      /* top: 8px; */
      inline-size: 16px;
      block-size: 16px;
      background-color: ${colors.gray5};
      border: 1px solid ${colors.gray3};
      border-radius: 2px;
    }

    &:hover {
      color: ${colors.solidBlackHover};
      cursor: pointer;

      &::before {
        background-color: ${colors.gray4};
      }
    }

    span {
      padding-inline: 32px ${spacing.small};
      font-size: ${fontSizes.small};
      line-height: ${lineHeights.s};
      block-size: auto;
      border-block-start: none;
    }

    .ImageWrapper {
      position: absolute;
      inset-block-start: 6px;
      display: inline-flex;
      inline-size: 16px;
      block-size: 16px;
      overflow: hidden;

      &.Color {
        border-radius: 50%;
        border: 1px solid ${colors.gray4};
      }

      img {
        inline-size: 100%;
      }
    }
  }

  input:checked + .CheckboxLabel::before {
    background-color: ${colors.solidBlack};
    border-color: ${colors.solidBlack};
    background-image: url('/assets/gfx/check-black.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
  }

  .BottomSection {
    position: fixed;
    box-sizing: border-box;
    inline-size: 100%;
    inset-block-end: 0;
    inset-inline-start: 0;
    display: flex;
    flex-direction: row;
    background: ${colors.white};
    box-shadow: 0 0 3px 0 ${colors.boxshadowTransparent};
    z-index: 2;

    button {
      block-size: 48px;
    }
  }

  .ResultContainer {
    h4 {
      margin: 0 0 8px;
      font-size: 16px;
      font-weight: ${fontWeights.fontMedium};
    }

    ul {
      margin-block-start: ${spacing.xsmall};
    }

    /* li {
      list-style: none;
      border-bottom: 1px solid ${colors.gray3};
      padding: 16px 0;
      font-size: 14px;
    } */

    .ChoiceContainer {
      padding-block-end: ${spacing.small};
    }

    .ChoicesContainer {
      padding: 0 ${spacing.small};
      margin: 0 -${spacing.small};
      margin-block-end: ${spacing.xsmall};
    }

    .ChoicesAndHeadingContainer {
      margin: 0 -${spacing.small};
      padding: ${spacing.small};
      padding-block-end: 0;
      border-block-start: 1px solid ${colors.gray4};

      h2 {
        margin-block-start: 0;
      }
    }

    .RestartButton {
      padding: 0;
      margin: auto;
      margin-block-start: ${spacing.small};
      display: inline-flex;

      :hover {
        color: ${colors.solidBlackHover};
      }
    }

    .RestartButtonContainer {
      text-align: start;
    }

    p {
      font-size: ${fontSizes.bodyS};
      line-height: ${lineHeights.bodyS};
      text-align: center;
      margin: 0;
    }

    h5 {
      font-size: ${fontSizes.bodyS};
      line-height: ${lineHeights.bodyS};
      margin-block-end: ${spacing.xsmall};
    }

    .ProductInfo {
      text-align: start;
    }

    .BestRecommendationsContainer,
    .OtherRecommendationsContainer {
      border-block-start: 1px solid ${colors.gray4};
      margin: 0 -${spacing.small};
      padding: 0 ${spacing.small};
    }
  }

  .DoneContainer {
    margin-block: ${spacing.regular};

    h1 {
      margin-block: ${spacing.small} ${spacing.xsmall};
    }
  }

  .LoaderWrapper {
    padding-block-start: 40px;
  }
`;
