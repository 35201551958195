import { useWindowSize } from 'react-use';

import { useGlobal } from '@hultafors/solidgear/hooks';
import { SafetyStandardTableBlockFragment } from '@hultafors/solidgear/types';

import { Paragraph } from '../paragraph/paragraph';
import { SafetyStandardTable } from '../SafetyStandardTable/SafetyStandardTable';

import {
  Description,
  DescriptionsWrapper,
  SafetyStandardTableBlockStyled,
  SafetyStandardTableHeader,
  TextContent,
} from './safety-standard-table-block.styled';

interface SafetyStandardTableBlockProps
  extends SafetyStandardTableBlockFragment {
  singleSafetyClass?: string;
}

export const SafetyStandardTableBlock: React.FC<
  SafetyStandardTableBlockProps
> = ({
  title,
  description,
  includedSafetyClasses,
  classes,
  singleSafetyClass,
  classDescriptions = [],
}) => {
  const { settings } = useGlobal();
  const windowSize = useWindowSize();

  const splitToChunks = (array: any[], numberOfParts: number) => {
    const copiedArray = [...array];
    const result = [];
    for (let i = numberOfParts; i > 0; i--) {
      result.push(copiedArray.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  };

  const tableMapper = (windowWidth: number) => {
    let tableArray;

    if (windowWidth <= 968) {
      tableArray = splitToChunks(includedSafetyClasses, 2);
    }

    if (windowWidth <= 650) {
      tableArray = splitToChunks(includedSafetyClasses, 3);
    }

    if (windowWidth <= 540) {
      tableArray = splitToChunks(includedSafetyClasses, 6);
    }
    if (windowWidth <= 385) {
      tableArray = splitToChunks(includedSafetyClasses, 9);
    }

    if (tableArray !== undefined && tableArray.length > 0) {
      return tableArray.map((array, i) => {
        if (array.length > 0) {
          return (
            <SafetyStandardTable
              key={`Safety-table-${i}`}
              safetyClassTable={classes}
              includedSafetyClasses={array}
              singleSafetyClass={singleSafetyClass}
            />
          );
        }
      });
    }
    return (
      <SafetyStandardTable
        safetyClassTable={classes}
        includedSafetyClasses={includedSafetyClasses}
        singleSafetyClass={singleSafetyClass}
      />
    );
  };

  return (
    classes?.length > 0 && (
      <SafetyStandardTableBlockStyled>
        <SafetyStandardTableHeader>
          <TextContent>
            <h2>{title}</h2>
            <Paragraph>{description}</Paragraph>
          </TextContent>
        </SafetyStandardTableHeader>
        {classes.length > 0 && tableMapper(windowSize.width)}
        <DescriptionsWrapper>
          {classDescriptions.map((classDescription: any, i: number) => {
            return (
              <Description key={`${classDescription.id}-${i}`}>
                <Paragraph>{classDescription.title}</Paragraph>
                <Paragraph>{classDescription.description}</Paragraph>
              </Description>
            );
          })}
        </DescriptionsWrapper>
      </SafetyStandardTableBlockStyled>
    )
  );
};
