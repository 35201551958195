import { useContext } from 'react';

import { GuideContext, GuideContextValue } from '@hultafors/solidgear/context';

export const useShoeGuide = (): GuideContextValue => {
  const context = useContext(GuideContext);
  if (!context) {
    throw new Error('useShoeGuide must be used within GuideContext!');
  }
  return context;
};
