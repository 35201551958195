import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/solidgear/helpers';

export const StyledProductDetailVideoPlayer = styled.div`
  box-sizing: border-box;
  max-width: 831.67px;
  width: 100%;
  margin-block-start: ${spacing.large};
  padding-inline: ${spacing.small};

  .vjs-big-play-button::before {
    font-size: ${fontSizes.header2Content};
    padding: ${spacing.small};
    background-color: ${colors.solidBlack};
    border-radius: 100%;
    opacity: 0.9;
    color: ${colors.gray4};
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin-inline-start: 12px;
  }
`;
