import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const ProductDetailInformationStyled = styled.div`
  margin-top: -${spacing.regular};
  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin-top: 0;
  }

  position: relative;
  h1 {
    font-style: normal;
    font-weight: ${fontWeights.fontMedium};
    font-size: ${fontSizes.header3Content};
    line-height: ${lineHeights.header3Content};
    font-family: ${fontFamilies.fontRegularBold};
    margin-top: 0;
    padding-right: 24px;
  }

  .NameAndFavoriteContainer {
    position: relative;
  }
  .Favorite {
    position: absolute;
    top: -2px;
    right: 0;
  }
  h3 {
    margin: 0;
  }
  .ArticleNumber {
    margin: 0;
    margin-top: ${spacing.xsmall};
    color: ${colors.gray1};
  }
  .NewLabel {
    font-family: ${fontFamilies.fontRegularBold};
    font-size: ${fontSizes.bodyS};
    line-height: ${lineHeights.bodyS};
    color: ${colors.solidOrange};
  }
  .RRPLabel {
    color: ${colors.gray1};
    text-transform: uppercase;
    margin-left: ${spacing.xsmall};
  }
  .PriceWrapper {
    display: flex;
    align-items: baseline;
    p {
      margin-bottom: 0;
    }
  }
  .FeatureList {
    margin: 0;
    margin-bottom: ${spacing.regular};
    padding: 0;
    ul {
      margin: 0;
      padding: 0;
      padding-left: 1em;
    }
    li {
      color: ${colors.solidBlack};
      font-style: normal;
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.body};
    }
  }

  .Placeholder {
    height: ${spacing.regular};
  }

  .SizeGuideButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: ${spacing.small};

    .SizeGuideWrapper {
      display: flex;
      justify-content: flex-end;
      svg {
        margin-left: ${spacing.xsmall};
        transition: all 0.3s ease;
      }
      &:hover {
        cursor: pointer;
        svg {
          transform: translateX(4px);
        }
      }
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.bodyXS};
    }
  }

  .ChooseSizeButton {
    button:first-of-type {
      margin-bottom: ${spacing.small};
    }
    button:last-of-type {
      margin-bottom: ${spacing.medium};
    }
  }

  .Materials {
    padding-top: ${spacing.regular};
    border-top: 1px solid ${colors.gray4};

    .MaterialsTitle {
      font-size: ${fontSizes.header3Content};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
      margin-bottom: ${spacing.regular};
    }

    .LogoWrapper {
      display: flex;
      flex-wrap: wrap;

      img {
        height: 48px;
        width: auto;
        max-width: 100px;
        margin: 0 0 ${spacing.small} 0;
      }
      p {
        margin: 0;
      }
      .MaterialItem {
        padding-top: ${spacing.xxsmall};
        padding-left: 0;
        padding-right: ${spacing.regular};
        display: flex;
        justify-content: center;
      }
    }
  }
  .MaterialsLink {
    margin-top: ${spacing.regular};
  }
  .MaterialsLink:hover {
    &::after {
      transition: all 0.3s ease;
      transform: translateX(4px);
    }
  }
`;
