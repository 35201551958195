import { TextLinkStyled } from './text-link.styled';
interface TextLinkProps {
  href: string;
  className?: string;
  iconUrl?: string;
  alt?: string;
  children?: React.ReactNode;
}

export const TextLink: React.FC<TextLinkProps> = (props) => {
  return (
    <TextLinkStyled
      href={props.href || ''}
      className={props.className}
      $iconUrl={props.iconUrl}
    >
      {props.children}
    </TextLinkStyled>
  );
};
