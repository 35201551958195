import styled from 'styled-components';

import { spacing } from '@hultafors/solidgear/helpers';

export const ContentAreaStyled = styled.div`
  margin: 0 auto;
`;

/* When using contentAreaBlocks without contentAreawrapper */
export const BlockWrapper = styled.div`
  width: 1400px;
  max-width: calc(100vw - 40px);
  margin: 0 auto;

  > * + * {
    margin-block-start: ${spacing.medium};
  }
`;
