import { ListApiFilterValue } from '@hultafors/shared/types';

import { colors } from '@hultafors/solidgear/helpers';
import {
  SelectionValue,
  ShoeGuideSelection,
  Step,
} from '@hultafors/solidgear/types';

import { H2 } from '../H2/H2';
import { RadioButton } from '../RadioButton/RadioButton';

import { ShoeGuideStyled } from './ShoeGuide.styled';

interface SingleChoiceProps {
  handleChange(...args: any[]): void;
  title?: string;
  selection: ShoeGuideSelection;
  filters: ListApiFilterValue[];
  currentStep?: Step;
}

/* For radio style selection */
export const SingleChoice: React.FC<SingleChoiceProps> = ({
  title,
  filters,
  selection,
  currentStep,
  handleChange,
}) => {
  const isSelected = (item: ListApiFilterValue) => {
    if (!currentStep?.name) {
      return false;
    }
    if (
      currentStep.name === 'start'
      || currentStep.name === 'result'
      || currentStep.name === 'filters'
    ) {
      return false;
    }
    if (!selection?.[currentStep.name]) {
      return false;
    }
    const selectionValue = selection[currentStep.name];
    if (Array.isArray(selectionValue)) {
      return false;
    }
    if (item.id === selectionValue.id) {
      return true;
    }
    return false;
  };

  if (!filters?.length) {
    return (
      <ShoeGuideStyled>
        <div className="GuideStepHeadingContainer">
          <H2>{title}</H2>
        </div>
        <div className="AnswersContainer">
          <div className="TopLevelAnswersContainer">
            <div className="LoaderWrapper">...Loading</div>
          </div>
        </div>
      </ShoeGuideStyled>
    );
  }
  function filterMapper(item: ListApiFilterValue, index: number) {
    const value
      = currentStep?.name
      && currentStep.name !== 'start'
      && currentStep.name !== 'result'
      && !Array.isArray(selection[currentStep.name])
        ? (selection[currentStep.name] as SelectionValue).id ?? ''
        : '';

    const changed = () => {
      if (currentStep?.name) {
        handleChange(currentStep.name, item);
      }
    };
    return (
      <div key={`Radio-${index}`} className="RadioAnswerContainer">
        <RadioButton
          id={`${item.id}`}
          name={item.description}
          label={item.description}
          color={colors.solidOrange}
          value={value}
          isSelected={isSelected(item)}
          changed={changed}
        />
      </div>
    );
  }
  return (
    <ShoeGuideStyled>
      <div className="GuideStepHeadingContainer">
        <H2>{title}</H2>
      </div>
      <div className="AnswersContainer">
        <div className="TopLevelAnswersContainer">
          {filters.map(filterMapper)}
        </div>
      </div>
    </ShoeGuideStyled>
  );
};
