import { Fragment } from 'react';

import { ProductFilter } from '@hultafors/shared/types';

import { TextButton } from '../text-button/text-button';

import { SelectedFiltersStyled } from './selected-filters.styled';
interface SelectedFiltersProps {
  filters?: ProductFilter[];
  filterChange({ AttrId, ValueId }: { AttrId: string; ValueId: string }): void;
}

export const SelectedFilters: React.FC<SelectedFiltersProps> = ({
  filters = [],
  filterChange,
}) => {
  const activeFilter = (item: any) => {
    return item.active === true;
  };
  return (
    <SelectedFiltersStyled>
      {!!filters.length && (
        <div className="SelectedFilters">
          {filters.map((item, i) => {
            return (
              <Fragment key={`Filter-Frag-${i}`}>
                {item.values.filter(activeFilter).map((child, j) => (
                  <TextButton
                    className="TextButton"
                    iconUrl="/assets/gfx/circle_close.svg"
                    onClick={() =>
                      filterChange({
                        AttrId: `${item.id}`,
                        ValueId: `${child.id}`,
                      })}
                    key={`Child-Frag-${i}-${j}`}
                  >
                    {child.description}
                  </TextButton>
                ))}
              </Fragment>
            );
          })}
        </div>
      )}
    </SelectedFiltersStyled>
  );
};
