import { ContentPlugFragment } from '@hultafors/solidgear/types';

import { ContentPlug } from '../content-plug/content-plug';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { Section } from '../section/section';

interface PlugGridProps {
  contentPlugs?: ContentPlugFragment[];
  className?: string;
}

export const PlugGrid: React.FC<PlugGridProps> = ({
  contentPlugs = [],
  className = 'MoreMarginBottom',
}) => {
  if (!contentPlugs.length) {
    return null;
  }

  function plugMapper(plug: ContentPlugFragment, index: number) {
    return (
      <GridChild
        key={`plugGridKey${index}`}
        className="GridChild"
        columnSpan={[{ columns: 4 }, { breakpoint: 'mobileMax', columns: 6 }]}
      >
        <ContentPlug title={plug.title} url={plug.url} image={plug.image} />
      </GridChild>
    );
  }
  return (
    <Section className={className}>
      <Grid
        columnGap={[{ columnGap: 16 }]}
        rowGap={[{ rowGap: 16 }, { breakpoint: 'mobileMax', rowGap: 16 }]}
      >
        {contentPlugs.map(plugMapper)}
      </Grid>
    </Section>
  );
};
