import { CSSProperties } from 'styled-components';

import { GridChildColumnSpanProp, StyledGridChild } from './grid-child.styled';
interface GridChildProps {
  className?: string;
  children?: React.ReactNode;
  align?: CSSProperties['justifySelf'];
  alignSelf?: CSSProperties['alignSelf'];
  padding?: CSSProperties['padding'];
  rowStart?: CSSProperties['gridRowStart'];
  rowSpan?: CSSProperties['gridRowEnd'];
  columnSpan?: GridChildColumnSpanProp;
}

export const GridChild: React.FC<GridChildProps> = ({
  className,
  children,
  align = 'stretch',
  alignSelf,
  padding,
  rowSpan,
  rowStart,
  columnSpan,
}) => {
  return (
    <StyledGridChild
      $align={align}
      className={className}
      $alignSelf={alignSelf}
      $padding={padding}
      $rowSpan={rowSpan}
      $rowStart={rowStart}
      $columnSpan={columnSpan}
    >
      {children}
    </StyledGridChild>
  );
};
