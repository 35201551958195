import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { ImageFragment } from '@hultafors/solidgear/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H2 } from '../H2/H2';
import { HeroImage } from '../HeroImage/HeroImage';

import { LargePlugStyled } from './large-plug.styled';

interface LargePlugProps {
  title?: string;
  imageDesktop?: ImageFragment | null;
  imageTablet?: ImageFragment | null;
  imageMobile?: ImageFragment | null;
  ctaTitle?: string;
  ctaUrl?: string;
  small?: boolean;
  btnLinkClicked?: React.MouseEventHandler;
}

export const LargePlug: React.FC<LargePlugProps> = ({
  title = '',
  ctaTitle = '',
  ctaUrl = '',
  small,
  imageDesktop,
  imageTablet,
  imageMobile,
  btnLinkClicked,
}) => {
  const { settings } = useGlobal();
  return (
    <LargePlugStyled
      $image={!!imageMobile || !!imageDesktop || !!imageTablet}
      $small={small}
    >
      <HeroImage
        mobile={imageMobile}
        tablet={imageTablet}
        desktop={imageDesktop}
      />
      <div className="Content">
        <Grid
          className="Grid"
          columns={[{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }]}
        >
          <GridChild
            columnSpan={[
              { columns: 4 },
              {
                breakpoint: 'mobileMax',
                columns: small ? 12 : 6,
                start: small ? 1 : 4,
              },
              {
                breakpoint: 'desktop',
                columns: small ? 12 : 4,
                start: small ? 1 : 5,
              },
            ]}
          >
            <div className="Inner">
              {title && <H2>{title}</H2>}
              {ctaTitle && (
                <ButtonLink
                  href={createUrl(ctaUrl, settings)}
                  className="White"
                  onClick={btnLinkClicked}
                >
                  {ctaTitle}
                </ButtonLink>
              )}
            </div>
          </GridChild>
        </Grid>
      </div>
    </LargePlugStyled>
  );
};
