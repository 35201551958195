import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../paragraph/paragraph';

import { HeroCampaignContentStyled } from './hero-campaign-content.styled';
interface HeroCampaignContentProps {
  className?: string;
  playVideo?: (...args: any[]) => any;
  preTitle?: string | null;
  title?: string | null;
  ingress?: string | null;
  linkUrl?: string | null;
  linkText?: string | null;
}
export const HeroCampaignContent: React.FC<HeroCampaignContentProps> = ({
  className,
  preTitle = '',
  title = '',
  ingress = '',
  linkUrl = '',
  linkText = '',
}) => {
  const { settings } = useGlobal();

  if (!ingress && !title) {
    return null;
  }
  return (
    <HeroCampaignContentStyled className={className}>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 7, start: 2 },
            { breakpoint: 'desktopMedium', columns: 5, start: 2 },
          ]}
        >
          {preTitle && (
            <Paragraph color="white" bold={true} hasLeftBorder={true}>
              {preTitle}
            </Paragraph>
          )}
          {title && (
            <HTag
              type="h1"
              styleType="header2"
              verticalMargin="small"
              color="white"
            >
              {title}
            </HTag>
          )}
          {ingress && (
            <HTag
              type="h2"
              styleType="header3Content"
              className="BottomRegularSpacing"
              color="white"
            >
              {ingress}
            </HTag>
          )}
          {linkText && linkUrl && (
            <ButtonLink
              className="White"
              href={createUrl(linkUrl, settings) || ''}
            >
              {linkText}
            </ButtonLink>
          )}
        </GridChild>
        <GridChild
          className="HeroContentWrapperTwo"
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 3, start: 9 },
            { breakpoint: 'desktopMedium', columns: 2, start: 11 },
            { breakpoint: 'desktopLarge', columns: 1, start: 12 },
          ]}
        />
      </Grid>
    </HeroCampaignContentStyled>
  );
};
