import { ButtonLinkStyled } from './ButtonLink.styled';

interface ButtonLinkProps {
  href: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
  gray?: boolean;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  href = '',
  className,
  disabled,
  children,
  gray,
  onClick,
}) => {
  return (
    <ButtonLinkStyled
      href={href ?? ''}
      className={className}
      $disabled={disabled}
      $gray={gray}
      onClick={onClick}
    >
      {children}
    </ButtonLinkStyled>
  );
};
