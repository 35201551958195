import dynamic from 'next/dynamic';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/solidgear/hooks';

import LogoSvg from '../../svg/solid-gear-logo.svg';

import styles from './footer.module.scss';

const FooterStyled = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Footer),
);

const FooterOld = dynamic(() =>
  import('../footer-old/footer-old').then((mod) => mod.FooterOld),
);

interface FooterMenuLink {
  title?: string;
  url?: string;
}
interface FooterMenuItem {
  heading?: string;
  links?: FooterMenuLink[];
  includeCookieToggle?: boolean;
}

export const Footer = () => {
  const { footer, global } = useGlobal();
  const { toggle, activeMarket } = useMarketPicker();

  if (!footer.useNewFooter) {
    return <FooterOld />;
  }

  const Logo = () => (
    <div className={styles['logo']}>
      <LogoSvg aria-hidden={true} />
    </div>
  );

  return (
    <FooterStyled
      activeMarket={activeMarket}
      brandLinks={footer?.hultaforsBrands ?? []}
      bottomIntro={footer?.bottomIntro ?? ''}
      introText={footer.introText ?? ''}
      items={(footer?.menuItems as FooterMenuItem[]) ?? []}
      logoAriaLabel="Solid Gear Footwear"
      logoIcon={<Logo />}
      moreInfoText={footer?.moreInfoText ?? ''}
      selectCountryLabel={global?.changeLanguage ?? ''}
      socialMediaLabel={footer?.socialMediaLabel ?? ''}
      socialMediaMenu={footer?.socialMediaMenu ?? []}
      toggleMarketPicker={toggle}
      cookieSettingsLabel={footer?.cookieSettings ?? ''}
    />
  );
};
