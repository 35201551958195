import clsx from 'clsx';

import {
  ClassRowFragment,
  SafetyClassFragment,
  SafetyClassTableBlockFragment,
} from '@hultafors/solidgear/types';

import { Paragraph } from '../paragraph/paragraph';

import styles from './safety-class-table.module.scss';

interface SafetyClassTableProps {
  safetyClassTable?: SafetyClassTableBlockFragment['classes'];
  includedSafetyClasses: SafetyClassFragment[];
  singleSafetyClass?: string;
}

export const SafetyClassTable: React.FC<SafetyClassTableProps> = ({
  safetyClassTable,
  includedSafetyClasses,
  singleSafetyClass,
}) => {
  function rowsMapper(row: ClassRowFragment, index: number) {
    return (
      <div className={styles['table-row']} key={`Table-Row-${index}`}>
        <Paragraph>{row.title}</Paragraph>
        <div className={styles['dots-wrapper']}>
          {includedSafetyClasses.map((SC, j) => {
            const exists = Boolean(
              row.safetyClasses.find((x) => x.name === SC.name),
            );
            const isSingleSafetyClass =
              singleSafetyClass && SC.name === singleSafetyClass;

            const active = isSingleSafetyClass ?? true;

            const classNames = clsx(styles['dot'], {
              [`${styles['active']}`]: active,
              [`${styles['hidden']}`]: !exists,
            });
            return (
              <div
                key={`Table-Row-${j}`}
                className={classNames}
                style={{ backgroundColor: SC.color?.hex }}
              />
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className={styles['safety-class-table']}>
      <div className={styles['safety-class']}>
        {includedSafetyClasses.map((C, i) => (
          <Paragraph style={{ color: C.color?.hex }} key={`Table-Column-${i}`}>
            {C.name}
          </Paragraph>
        ))}
      </div>
      {safetyClassTable?.map(rowsMapper)}
    </div>
  );
};
