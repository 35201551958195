import { TextButtonStyled } from './text-button.styled';
interface TextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconUrl?: string;
  iconLeft?: boolean;
}

export const TextButton: React.FC<TextButtonProps> = ({
  children,
  iconUrl,
  iconLeft,
  ...rest
}) => {
  return (
    <TextButtonStyled {...rest} $iconUrl={iconUrl} $iconLeft={iconLeft}>
      {children}
    </TextButtonStyled>
  );
};
