import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageFragment, ResponsiveImage } from '@hultafors/solidgear/types';

import { DesktopImage, MobileImage, TabletImage } from './HeroImage.styled';

export interface HeroImageProps {
  desktop?: ImageFragment | null;
  mobile?: ImageFragment | null;
  tablet?: ImageFragment | null;
  priority?: boolean;
  breakpointTablet?: keyof typeof breakpoints;
  breakpointDesktop?: keyof typeof breakpoints;
}

export const HeroImage: React.FC<HeroImageProps> = ({
  mobile,
  tablet,
  desktop,
  priority,
  breakpointTablet = breakpoints.tablet,
  breakpointDesktop = breakpoints.desktop,
}) => {
  const ImageElement: React.FC<{
    data: ResponsiveImage;
    hasPriority?: boolean;
  }> = ({ data, hasPriority = priority }) => {
    return data ? (
      <Image
        src={data.src}
        alt={data.alt || ''}
        sizes="100vw"
        fill
        priority={hasPriority}
        placeholder="blur"
        blurDataURL={data.base64 || ''}
      />
    ) : null;
  };
  return desktop?.responsiveImage ||
    tablet?.responsiveImage ||
    mobile?.responsiveImage ? (
    <>
      {mobile?.responsiveImage && (
        <MobileImage
          $breakpointMax={
            tablet?.responsiveImage ? breakpointTablet : breakpointDesktop
          }
        >
          <ImageElement data={mobile.responsiveImage} />
        </MobileImage>
      )}

      {tablet?.responsiveImage && (
        <TabletImage
          $breakpointMin={breakpointTablet}
          $breakpointMax={breakpointDesktop}
        >
          <ImageElement data={tablet.responsiveImage} hasPriority={false} />
        </TabletImage>
      )}
      {desktop?.responsiveImage && (
        <DesktopImage
          $breakpointMin={breakpointDesktop}
          $force={!mobile?.responsiveImage && !tablet?.responsiveImage}
        >
          <ImageElement
            data={desktop.responsiveImage}
            hasPriority={!mobile?.responsiveImage && !tablet?.responsiveImage}
          />
        </DesktopImage>
      )}
    </>
  ) : null;
};
