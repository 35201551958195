import styled from 'styled-components';

import { spacing } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
export const StyledRetailersAdditionalSelections = styled.div`
  margin-top: ${spacing.regular};

  .RadiusSelectContainer {
    label {
      font-size: ${fontSizes.bodyS};
    }
  }
`;
