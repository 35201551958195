import Markdown from 'markdown-to-jsx';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H2 } from '../H2/H2';

import { ContentBlockStyled } from './content-block.styled';

interface ContentBlockProps {
  title?: string;
  description?: string;
  className?: string;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  description,
  className,
}) => {
  return (
    <ContentBlockStyled className={className}>
      <div>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 8, start: 3 },
              { breakpoint: 'desktopMedium', columns: 6, start: 4 },
            ]}
          >
            <div className="wrapper">
              {title && <H2>{title}</H2>}
              {description && (
                <Markdown className="BodyText" options={{ forceBlock: true }}>
                  {description}
                </Markdown>
              )}
            </div>
          </GridChild>
        </Grid>
      </div>
    </ContentBlockStyled>
  );
};
