import { Image } from '../Image/Image';
import { Paragraph } from '../paragraph/paragraph';

import { DownloadFileBoxStyled } from './DownloadFileBox.styled';
interface DownloadFileBoxProps {
  url?: string;
  size?: string;
  label?: string;
  filetype?: string;
}
export const DownloadFileBox: React.FC<DownloadFileBoxProps> = ({
  url,
  size,
  label,
  filetype,
}) => {
  // Add zero-width space to allow line break after underscore
  const soft = label?.replace(/_/g, '_\u200b') ?? '';
  return (
    <DownloadFileBoxStyled>
      <a href={url || ''}>
        <Image
          className="Icon"
          src="/assets/gfx/file_16.svg"
          caption="file icon"
        />
        <div className="TextWrapper">
          <p className="Label">{soft}</p>
          <Paragraph xSmall className="Size">
            {filetype}
            {' '}
            {size}
          </Paragraph>
        </div>
        <Image
          className="Icon"
          src="/assets/gfx/download_16.svg"
          caption="download icon"
        />
      </a>
    </DownloadFileBoxStyled>
  );
};
