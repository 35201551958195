import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

import { Section } from '../section/section';

export const ProductListHeaderStyled = styled.section`
  .Grid {
    grid-auto-flow: dense;
  }

  .Content {
    padding: 0 ${spacing.small};
    margin-block-start: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: 0 ${spacing.regular};
    }

    @media screen and (width >= calc(${breakpoints.maxPageWidth} + ${spacing.medium})) {
      padding: 0;
    }

    .Category {
      display: flex;
      align-items: center;
      block-size: ${spacing.regular};
      margin: 0;
      margin-block-end: ${spacing.xsmall};

      &.Line::before {
        content: '';
        display: block;
        margin-inline-end: ${spacing.xsmall};
        block-size: 16px;
        inline-size: 2px;
        background-color: ${colors.solidBlack};
      }
    }

    h2 {
      margin: 0;
      padding: 0;
      margin-block-end: ${spacing.xsmall};
      font-family: ${fontFamilies.fontRegularBold};
      font-style: normal;
      font-weight: ${fontWeights.fontMedium};
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.body};
    }

    h1 {
      margin: 0;
      margin-block-end: ${spacing.small};
      padding: 0;
      font-family: ${fontFamilies.fontHero};
      font-style: normal;
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.header2};
      line-height: ${lineHeights.header2};
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.header3Content};
      line-height: ${lineHeights.header3Content};
    }
  }

  .SafetyClass {
    padding: 0 ${spacing.small};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: 0;
    }

    .Line {
      margin-block-end: ${spacing.regular};
      inline-size: 80px;
      border-block-end: 1px solid ${colors.solidBlack};

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        inline-size: 102px;
      }
    }

    p {
      margin: 0;
    }
  }

  .IconAndTextButtonWrapper {
    display: flex;
    align-items: flex-end;
  }

  .SafetyClassWrapper {
    margin-block-start: 12px;

    img {
      block-size: 48px;
      inline-size: 48px;
      margin-inline-end: ${spacing.xsmall};
    }
  }
`;

export const HeroImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-block-start: ${spacing.medium};
    padding-inline-end: ${spacing.regular};
  }

  @media screen and (width >= calc(${breakpoints.maxPageWidth} + ${spacing.medium})) {
    padding-inline-end: 0;
  }
`;

export const DividerLine = styled.div`
  border-bottom: 1px solid ${colors.gray4};
`;

export const DividerSection = styled(Section)`
  margin: ${spacing.regular} 0;
`;
