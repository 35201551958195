import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/solidgear/helpers';

export const PagerStyled = styled.div`
  margin-top: ${spacing.regular};

  .PagerInner {
    max-inline-size: 250px;
    margin: 0 auto;
  }

  .Pager {
    margin-block-end: ${spacing.small};
    text-align: center;
    font-size: ${fontSizes.small};
    letter-spacing: 0.1rem;
  }

  .Gauge {
    margin: 0 0 ${spacing.block} 0;
    inline-size: 100%;
  }

  .Buttons {
    display: flex;
    flex-direction: column;

    button {
      margin-block-end: 10px;
      text-align: center;
      justify-content: center;
    }
  }
`;
