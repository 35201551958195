import {
  SafetyClassFragment,
  SafetyStandardTableBlockFragment,
} from '@hultafors/solidgear/types';

import { Paragraph } from '../paragraph/paragraph';

import {
  ColumnTitle,
  Dot,
  DotsWrapper,
  RowTitleWrapper,
  SafetyClass,
  SafetyStandardTableStyled,
  TableContainer,
  TableRow,
} from './SafetyStandardTable.styled';

interface SafetyStandardTableProps {
  safetyClassTable?: SafetyStandardTableBlockFragment['classes'];
  includedSafetyClasses: SafetyClassFragment[];
  singleSafetyClass?: string;
}

export const SafetyStandardTable: React.FC<SafetyStandardTableProps> = ({
  safetyClassTable,
  includedSafetyClasses,
  singleSafetyClass,
}) => {
  if (!safetyClassTable?.length) {
    return null;
  }
  return (
    <SafetyStandardTableStyled>
      <TableContainer>
        <SafetyClass>
          {includedSafetyClasses.map((C, i) => (
            <ColumnTitle key={`Table-Column-${i}`}>{C.name}</ColumnTitle>
          ))}
        </SafetyClass>
        {safetyClassTable.map((row: any, i) => (
          <TableRow key={`Table-Row-${i}`}>
            <RowTitleWrapper>
              {row.label && <Paragraph>{row.label}</Paragraph>}
              <Paragraph>{row.title}</Paragraph>
            </RowTitleWrapper>
            <DotsWrapper>
              {includedSafetyClasses.map((SC, j) => {
                const exists = Boolean(
                  row.safetyClasses.find((x: any) => x.name === SC.name),
                );
                const active = SC.name === singleSafetyClass ? true : false;
                return (
                  <Dot
                    $active={singleSafetyClass ? active : true}
                    $exists={exists}
                    key={`Table-Row-${j}`}
                  />
                );
              })}
            </DotsWrapper>
          </TableRow>
        ))}
      </TableContainer>
    </SafetyStandardTableStyled>
  );
};
