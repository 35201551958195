import { SafetyClassValue } from '@hultafors/solidgear/types';

import { SafetyClassIcon } from '../SafetyClassIcon/SafetyClassIcon';
import { TextButton } from '../text-button/text-button';

import { SafetyClassDivisionStyled } from './SafetyClassDivision.styled';

interface SafetyClassDivisionProps {
  safetyClassLabel: string;
  safetyClass: SafetyClassValue;
}

export const SafetyClassDivision: React.FC<SafetyClassDivisionProps> = ({
  safetyClassLabel,
  safetyClass,
}) => {
  const handleTextButtonPress = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    document.getElementById('safety_class')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const renderContent = () => {
    return (
      <SafetyClassDivisionStyled>
        <SafetyClassIcon safetyClass={safetyClass} filled={true} />
        <TextButton
          className="AnchorButton"
          iconUrl="/assets/gfx/arrow_2_down.svg"
          onClick={handleTextButtonPress}
        >
          {safetyClassLabel}
        </TextButton>
      </SafetyClassDivisionStyled>
    );
  };

  return safetyClass && renderContent();
};
