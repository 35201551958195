import { ImageFragment } from '@hultafors/solidgear/types';

import { Button } from '../button/button';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { H2 } from '../H2/H2';

import { SmallPlugStyled } from './small-plug.styled';
interface SmallPlugProps {
  title?: string;
  image?: ImageFragment | null;
  imageMobile?: ImageFragment | null;
  ctaTitle?: string;
  color?: string;
  background?: string;
  backgroundColor?: string;
  ctaClick?: React.MouseEventHandler;
  small?: boolean;
}

export const SmallPlug: React.FC<SmallPlugProps> = ({
  title = 'FIND THE RIGHT SAFETY SHOE',
  ctaTitle = 'Start guide',
  ctaClick,
  small,
  color,
  backgroundColor,
}) => {
  return (
    <SmallPlugStyled
      $color={color}
      $small={small}
      $backgroundColor={backgroundColor}
    >
      <div className="Content">
        <Grid
          className="Grid"
          columns={[{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }]}
        >
          <GridChild
            columnSpan={[
              { columns: 4 },
              {
                breakpoint: 'mobileMax',
                columns: small ? 12 : 6,
                start: small ? 1 : 4,
              },
              {
                breakpoint: 'desktop',
                columns: small ? 12 : 4,
                start: small ? 1 : 5,
              },
            ]}
          >
            <div className="Inner">
              {title && <H2>{title}</H2>}
              {ctaTitle && (
                <Button onClick={ctaClick} className="Black">
                  {ctaTitle}
                </Button>
              )}
            </div>
          </GridChild>
        </Grid>
      </div>
    </SmallPlugStyled>
  );
};
