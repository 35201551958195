import styled, { css, keyframes } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/solidgear/helpers';

const rotate = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); opacity: 1; }
  to   { transform: translate(-50%, -50%) rotate(360deg); opacity: 1; }
`;

const appear = keyframes`
  0% { transform: translate(-50%, 20%) scale(0.3); opacity: 0; }
  85%   { transform: translate(-50%, -60%) scale(1); opacity: 1 }
  100%   { transform: translate(-50%, -50%) scale(1); opacity: 1 }
`;

interface IconButtonStyledProps {
  $fullWidth?: boolean;
  $lowerCase?: boolean;
  $center?: boolean;
  $iconUrl?: string;
}

export const IconButtonStyled = styled.button<IconButtonStyledProps>`
  appearance: none;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  display: inline-block;
  height: ${spacing.medium};
  padding: 0 ${spacing.xsmall};
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid ${colors.black};
  font-size: ${fontSizes.xsmall};
  font-weight: ${fontWeights.fontMedium};
  color: ${colors.black};
  letter-spacing: 0.175rem;
  cursor: pointer;
  transition: 0.2s;
  text-transform: ${({ $lowerCase }) => ($lowerCase ? 'inherit' : 'uppercase')};

  ${({ $center }) =>
    $center
    && css`
      display: block;
      margin: 0 auto;
      text-align: center;
    `}

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    padding: 0 ${spacing.small};
  }

  &:hover {
    background-color: ${colors.black};
    color: ${colors.white};
  }

  &.Red {
    border: 1px solid ${colors.warning};
    background-color: ${colors.warning};
    color: ${colors.white};

    &:hover {
      border: 1px solid ${colors.warning};
      background-color: ${colors.warning};
    }
  }

  &.Black {
    border: 1px solid ${colors.black};
    background-color: ${colors.black};
    color: ${colors.white};

    &:disabled {
      cursor: not-allowed;
      border: 0;
      background-color: ${colors.black};
      transition: 0.2s;
      opacity: 0.5;
    }
  }

  &.Inverted {
    background-color: transparent;
    border: 1px solid ${colors.white};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.white};
      color: ${colors.black};
    }
  }

  &.Filter {
    block-size: 32px;
    background-color: transparent;
    border: 1px solid ${colors.border};
    border-radius: 100px;
    padding: 0 ${spacing.small};
    color: ${colors.black};
    font-size: ${fontSizes.small};
    font-weight: ${fontWeights.fontRegular};
    text-transform: unset;
    letter-spacing: 0;
    white-space: nowrap;

    &:hover {
      background-color: ${colors.gray4};
    }

    &.Selected {
      background-color: ${colors.black};
      border-color: ${colors.black};
      color: ${colors.white};
    }
  }

  &.Text {
    background-color: transparent;
    border: none;
    color: ${colors.black};
    font-weight: ${fontWeights.fontRegular};
    text-transform: unset;
    letter-spacing: 0.04em;
    font-size: ${fontSizes.small};

    &:hover {
      color: ${colors.gray2};
    }

    &:focus {
      outline: 0;
    }
  }

  &.IconButton {
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    background-color: transparent;
  }

  &.Icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      display: none;

      @media screen and (min-width: ${breakpoints.bigMobile}) {
        display: inline-flex;
        inline-size: ${spacing.regular};
        block-size: ${spacing.regular};
        background-image: ${({ $iconUrl }) => `url(${$iconUrl})`};
        background-repeat: no-repeat;
        background-size: contain;
        margin-inline-start: ${spacing.xsmall};
        transition: transform 0.1s linear;
      }
    }

    &.MobileFilter::after {
      content: '';
      display: inline-flex;
      inline-size: ${spacing.small};
      block-size: ${spacing.small};
      background-image: ${({ $iconUrl }) => `url(${$iconUrl})`};
      background-repeat: no-repeat;
      background-size: contain;
      margin-inline-start: ${spacing.xsmall};
      transition: transform 0.1s linear;
    }
  }

  &.SvgIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      inline-size: 16px;
      block-size: 16px;
      margin-inline-start: 16px;
    }

    &:hover {
      svg path {
        fill: ${colors.white};
      }
    }
  }

  &.Loading {
    position: relative;
    font-size: 0;
    transition: all 0.3s;

    &::after {
      content: '';
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      inline-size: 24px;
      block-size: 24px;
      background-image: url('/assets/gfx/loading-circle.svg');
      background-repeat: no-repeat;
      background-position: center;
      animation: ${rotate} 1s linear;
      animation-iteration-count: infinite;
      transform-origin: center;
    }

    &:hover::after {
      background-image: url('/assets/gfx/loading-circle-white.svg');
    }
  }

  &.NextIcon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      display: inline-flex;
      inline-size: ${spacing.small};
      block-size: ${spacing.small};
      background-image: ${({ $iconUrl }) => `url(${$iconUrl})`};
      background-repeat: no-repeat;
      background-size: contain;
      margin-inline-start: ${spacing.xsmall};
      transition: transform 0.1s linear;
    }
  }

  &.AddingInCart {
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    color: ${colors.warning};

    &::before {
      content: '';
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      inline-size: 24px;
      block-size: 24px;
      background-image: url('/assets/gfx/loading-circle-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      animation: ${rotate} 1s linear;
      animation-iteration-count: 1;
      transform-origin: center;
    }

    &::after {
      content: '';
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      inline-size: 32px;
      block-size: 32px;
      background-image: url('/assets/gfx/check_button.svg');
      background-repeat: no-repeat;
      background-position: center;
      transform: translate(-50%, 40%) scale(0.6);
      opacity: 0;
      animation: ${appear} 0.4s ease-out;
      animation-iteration-count: 1;
      animation-delay: 0.9s;
      animation-fill-mode: forwards;
      transform-origin: center;
    }
  }

  &:disabled {
    cursor: not-allowed;
    border: 0;
    color: ${colors.gray1};
    background-color: ${colors.warning};
    transition: 0.2s;
    opacity: 0.5;

    svg {
      opacity: 0.3;
    }
  }
`;
