import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const RelatedProductsStyled = styled.div`
  .NoSlider {
    padding-inline: ${spacing.small};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-inline: ${spacing.regular};
    }
  }

  .Hide {
    display: none;
  }

  .Show {
    display: block;
  }

  .DesktopOnly {
    display: none;
    padding: 0 ${spacing.regular};
  }

  .MobileOnly {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    .DesktopOnly {
      display: block;
    }

    .MobileOnly {
      display: none;
    }
  }

  h2 {
    margin: 0;
    margin-block-end: ${spacing.medium};
    text-align: center;
    font-family: ${fontFamilies.fontHero};
    font-size: ${fontSizes.header3};
    line-height: ${lineHeights.header3};
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block-end: ${spacing.large};
    }
  }

  .ButtonWrapper {
    max-inline-size: 335px;
    margin: 0 auto;
  }

  .Product {
    &:last-of-type {
      margin-block-end: 0;
    }

    @media screen and (min-width: ${breakpoints.mediumMobile}) {
      &:nth-of-type(3),
      &:nth-of-type(4) {
        margin-block-end: 0;
      }
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-block-end: 0;
    }
  }
`;
