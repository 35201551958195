export const colors = {
  active: '#4779F6',
  // This are not from the style guide and should be replaced
  black: 'rgba(0, 0, 0, 1)',

  blackHover: '#2D2D2D',

  border: '#E5E5E5',

  boxshadowTransparent: 'rgba(0, 0, 0, 0.1)',

  gray1: '#8D8D8D',

  gray2: '#B3B3B3',

  gray3: '#DEDEDE',

  gray4: '#EAEAEA',

  gray5: '#F7F7F7',

  gray6: '#F5F5F5',

  grayBtnHover: '#E9E8E8',

  // legacy
  hellBlue: '#0000FF',

  hellBlueHover: '#5F5FFF',

  o1: '#00CE9D',

  o2: '#D13BF6',

  ok: '#52E157',

  overlayTransparent: 'rgba(35, 33, 32, 0.3)',

  s1: '#FF687A',

  s1p: '#3988FF',

  s2p: '#00BCE5',

  s3: '#813BF6',

  solidBlack: '#111111',

  solidBlackHover: '#393939',

  solidOrange: '#FF9012',

  solidOrangeHover: '#F68607',

  warning: '#FD2020',
  white: 'rgba(255, 255, 255, 1)',
} as const;

export type Colors = keyof typeof colors;
