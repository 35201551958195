import { CSSProperties } from 'styled-components';

import { StyledParagraph } from './paragraph.styled';
interface ParagraphProps {
  center?: boolean;
  small?: boolean;
  xSmall?: boolean;
  className?: string;
  bold?: boolean;
  noMargin?: boolean;
  hasLeftBorder?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  color?: CSSProperties['color'];
}

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  className,
  style,
  center,
  small,
  xSmall,
  bold,
  hasLeftBorder,
  color,
  noMargin,
}) => {
  return (
    <StyledParagraph
      className={className}
      style={style}
      $center={center}
      $small={small}
      $xSmall={xSmall}
      $bold={bold}
      $hasLeftBorder={hasLeftBorder}
      $color={color}
      $noMargin={noMargin}
    >
      {children}
    </StyledParagraph>
  );
};
