import dynamic from 'next/dynamic';

import { generateMapUrl } from '@hultafors/shared/helpers';

import { OfficeFragment } from '@hultafors/solidgear/types';

import { ContentListWrapper } from '../contact-list-wrapper/contact-list-wrapper';
import { StoreItem } from '../store-item/store-item';

import { Label } from './contact-list.styled';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionOld),
);
const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItemOld),
);

interface ContactListProps {
  offices?: OfficeFragment[];
  localWebLabel?: string | null | undefined;
  viewOnMapLabel?: string | null | undefined;
}

export const ContactList: React.FC<ContactListProps> = ({
  offices = [],
  viewOnMapLabel,
  localWebLabel,
}) => {
  function officeMapper(item: OfficeFragment) {
    const key = `Store-${item.id}`;
    const mapLink = generateMapUrl(item.address, item.zipCode, item.name);
    return (
      <AccordionItem label={<Label>{item.name}</Label>} key={key} plus>
        <StoreItem
          label={item.name}
          address={item.address}
          zipCode={item.zipCode}
          city={item.city}
          country={item.name}
          mapLink={mapLink}
          viewOnMap={viewOnMapLabel}
          emailAddress={item.email}
          phone={item.phoneNumber}
          website={item.websiteUrl}
          localWeb={localWebLabel}
        />
      </AccordionItem>
    );
  }

  if (!offices.length) {
    return null;
  }
  return (
    <ContentListWrapper>
      <Accordion allowZeroExpanded>{offices.map(officeMapper)}</Accordion>
    </ContentListWrapper>
  );
};
