import { ListApiFilterValue } from '@hultafors/shared/types';

import { ShoeGuideSelection, Step } from '@hultafors/solidgear/types';

import { H2 } from '../H2/H2';

import { ShoeGuideStyled } from './ShoeGuide.styled';

interface MultiChoiceProps {
  handleChange(...args: any[]): void;
  title?: string;
  selection: ShoeGuideSelection;
  filters: ListApiFilterValue[];
  currentStep?: Step;
  className?: string;
}

/* For radio style selection */
export const MultiChoice: React.FC<MultiChoiceProps> = ({
  handleChange,
  title,
  selection,
  filters,
  currentStep,
  className,
}) => {
  const isSelected = (item: ListApiFilterValue) => {
    if (!currentStep?.name) {
      return false;
    }
    if (
      currentStep.name === 'start'
      || currentStep.name === 'result'
      || currentStep.name === 'filters'
    ) {
      return false;
    }
    if (!selection?.[currentStep.name]) {
      return false;
    }
    const selectionValue = selection[currentStep.name];
    if (
      Array.isArray(selectionValue)
      && selectionValue.find(({ id }) => id === item.id)
    ) {
      return true;
    }
    if (!Array.isArray(selectionValue) && selectionValue.id === item.id) {
      return true;
    }
    return false;
  };

  function filterMapper(item: ListApiFilterValue, index: number) {
    const id = `Filter-${item.id ?? index}`;
    const value = item.id;

    const onChange: React.ChangeEventHandler = () => {
      if (currentStep?.name) {
        handleChange(currentStep.name, item);
      }
    };
    return (
      <div key={`Check-${index}`} className="CheckboxContainer">
        <input
          id={id}
          name={item.description}
          type="checkbox"
          value={value}
          checked={isSelected(item)}
          onChange={onChange}
        />
        <label htmlFor={id} className="CheckboxLabel">
          <span>{item.description}</span>
        </label>
      </div>
    );
  }

  return (
    <ShoeGuideStyled className={className}>
      <div className="GuideStepHeadingContainer">
        <H2>{title}</H2>
      </div>
      <div className="AnswersContainerMultiChoice">
        <div className="TopLevelAnswersContainer">
          {filters.length > 0
            ? (
              <>{filters.map(filterMapper)}</>
              )
            : (
              <div className="LoaderWrapper">...Loading</div>
              )}
        </div>
      </div>
    </ShoeGuideStyled>
  );
};
