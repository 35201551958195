import DefMarkdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/solidgear/helpers';

import { Grid as DefGrid } from '../grid/grid';

export const Grid = styled(DefGrid)`
  width: 100%;
`;

export const Markdown = styled(DefMarkdown)`
  p:last-child {
    margin-block-end: 0;
  }
`;

export const DynamicWrapper = styled.div<{ $noPad?: boolean }>`
  display: flex;
  margin: ${spacing.medium} auto;
  padding: ${({ $noPad }) => ($noPad ? null : `0 ${spacing.small}`)};

  @media all and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.large} 0;
  }
`;
