import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/solidgear/helpers';

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    flex-basis: 100%;
    font-size: ${fontSizes.body};
  }
`;

export const StyledSelect = styled.select`
  appearance: none;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.xsmall};
  border: 0.5px solid ${colors.solidBlack};
  background: url('/assets/gfx/caret-down.svg');
  background-size: ${fontSizes.body};
  background-position: calc(100% - ${spacing.small}) center;
  background-repeat: no-repeat;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.small};
  }

  &:focus {
    outline: none;
  }

  option {
    padding: ${spacing.small} ${spacing.xsmall};
    line-height: 1.4em !important;

    &:disabled {
      color: ${colors.gray2};
    }
  }

  .ArrowIcon {
    inline-size: 8px;
    position: absolute;
    inset-block-start: 10px;
    inset-inline-end: 10px;
  }
`;
