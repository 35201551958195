import styled from 'styled-components';

import { fontSizes } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';

interface StyledH2Props {
  $center?: boolean;
}

export const StyledH2 = styled.h2<StyledH2Props>`
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSizes.header2};
  line-height: ${lineHeights.header2};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
`;
